/* THIS TEMP MAIN FILE WILL BE DIVIDED BY JS STRUCTURE */

var last = 0;
var PREVIEW_PAGE_NUM = 3;

function loginToModerator() {
    var form = $('#moderatorForm');
    var formData = new FormData(form[0]);
    var url = '/moderator/';
     $.ajax({
        type: "POST",
        url: url,
        dataType : "json",
        data: formData,
        processData: false,
        contentType: false,
        success: function (result) {
            if (result.auth_error) {
                toastr.error('Wrong email or password.');
                setTimeout(function(){ window.location.reload() }, 1000);
            } else if (result.success) {
                toastr.success('Login successful.');
                setTimeout(function(){ window.location.reload() }, 1000);
            }
        },
        error: function () {
            toastr.error('Unknown error!');
            setTimeout(function(){ window.location.reload() }, 1000);
        }
    });
}

function loginToPostNews(button) {

    if (Cookies.get('unpostedNewsItem') || localStorage.getItem('unpostedNewsItemData')) {
        localStorage.removeItem('unpostedNewsItemData');
        Cookies.remove('unpostedNewsItem');
    }

    var form = $('#createNewsForm');
    var form_parsley = form.parsley();
    form_parsley.validate();

    if (!form_parsley.isValid())
        return;

    $(button).prop('disabled', true);
    var rawFormData = form.serializeArray();
    var formData = {};

    rawFormData.forEach(function (element) {
        if (element.name !== 'photo') {
            formData[element.name] = element.value;
        }
    });
    formData['photo'] = files[0];

    Cookies.set('unpostedNewsItem', true, { path: location.href });
    localStorage.setItem('unpostedNewsItemData', JSON.stringify(formData));
    $('#createNewsBlock').hide();
    $('#loginToPostNewsBlock').show();
}

function dentistCreateNewsRedirect(e, linkElem) {
    e.preventDefault();

    var url = $(linkElem).attr('href');
    Cookies.set('navTabMemory', 'jsMainNavTab40', {expires: 1, path: location.href});
    Cookies.set('previousUrl', url, {expires: 1, path: location.href});

    location.href = url;
}

function addNews(e) {
    $('#customBodyErrors').hide();

    var parsleyErrors = $('#parsley-id-7');
    if (parsleyErrors.length) {
        parsleyErrors.show();
    }

    var form = $('#createNewsForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#createNewsSubmit').prop('disabled', true);
        var rawFormData = form.serializeArray();
        var formData = new FormData();

        rawFormData.forEach(function (element) {
            if (element.name !== 'photo') {
                formData.append(element.name, element.value);
            }
        });
        formData.append('photo', files[0]);

        e.preventDefault();
        loader.show();

        var postUrl = location.href.indexOf('manage') !== -1 ? location.href + 'create-news/' : location.href;

        $.ajax({
            type: "POST",
            url: postUrl,
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#createNewsSubmit').prop('disabled', false);
                loader.hide();

                if (result.success) {
                    toastr.success('News item created!');
                    setTimeout(function() {
                        if (location.href.indexOf('/manage/') !== -1) {
                            location.reload()
                        } else {
                            location.href = '/';
                        }
                    }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function BindShareButton() {
    $('.share-button').unbind('click');
    $('.share-button').click(function() {
        FB.ui({
            method: 'share',
            display: 'popup',
            href: $(this).attr('data-url')
        }, function (response) {
        });
    });
}

function resetTreatmentDuration(review_id) {
    var formData = new FormData();
    formData.append('reset_duration', true);
    formData.append('review_ids', review_id);
    var url = '/moderator/';
    $.ajax({
            type: "POST",
            url: url,
            dataType : "json",
            data: formData,
            processData: false,
            contentType: false,
            success: function () {
                toastr.success('Treatment duration was successfully reset.');
                setTimeout(function(){ window.location.reload() }, 1000);
            }
        });
}

function sendReviewStatus(status) {
    var checkedValue = $('.messageCheckbox:checked');
    var reviewIds = [];

    for (var i = 0; checkedValue[i]; ++i){
     reviewIds.push(checkedValue[i].value);
    }

    if (!reviewIds.length) {
        alert('Please check some review');
    } else {
        var formData = new FormData();
        formData.append(status, true);
        formData.append('review_ids', reviewIds);
        loader.show();
        var url = '/moderator/';
         $.ajax({
            type: "POST",
            url: url,
            dataType : "json",
            data: formData,
            processData: false,
            contentType: false,
            success: function (result) {
                loader.hide();
                if (result.approve_review) {
                    toastr.success('Review was successfully approved.');
                    setTimeout(function () {window.location.reload()}, 1000);
                }
                else if (result.reject_review) {
                    toastr.success('Review was successfully rejected.');
                    setTimeout(function () {window.location.reload()}, 1000);
                } else {
                    window.location.reload();
                }
            },
            error: function () {
                loader.hide();
                toastr.error('Unknown error!');
                setTimeout(function(){ window.location.reload() }, 1000);
            }
        });
    }

}

function filterObjects(input) {
    var str = input.value.toUpperCase();
    var a = 0;
    $.each($(input).parent().siblings(), function(index, el){
        var $el = $(el);
        var data = $el.find('a').html();
        var allDataStr = '';
        for (var field in data) {
            if (data.hasOwnProperty(field)) {
                allDataStr += data[field].toUpperCase();
            }
        }
        if (allDataStr.indexOf(str) === -1) {
            $el.hide();
        } else {
            $el.show();
        }
    })
}

function searchZipcode(button) {
    var zipcode = button.parents('form').find('input').val();
    if (zipcode.length > 2 && zipcode.length < 6 && Number(zipcode)){
        window.location = "/?zip-code=" + zipcode;
    }
}

function scrollToState(argument) {
  $('body').animate({scrollTop: $("#" + argument).offset().top - 53}, 500)
}

function scrollToTop() {
    $('html, body').animate({scrollTop : 0},800);
    return false;
}

function deleteReview() {
    var reviewId = $('#delete-post').attr('data-key');
     $.ajax({
            method: "DELETE",
            url: "/reviews/delete/",
            dataType: "json",
            data: {'reviewId':reviewId},
            success: function (result) {
                toastr.success('Review was successfully rejected.');
                setTimeout(function(){ window.location.reload() }, 1000);
            }
        }
    );
}

$(window).scroll(function(){
    $(this).scrollTop();

    if ($(this).scrollTop() > 500) {
        $('.scroll-to-top').fadeIn();
    } else {
        $('.scroll-to-top').fadeOut();
    }
});

function parseSearchQuery(el) {
    var query = window.location.search.substring(1);
    var arr = query.split('&');
    var isMatched = false;
    if ( query.match(el) ) {
        isMatched = true;
    }
    var matchedElement;
    var multipleElements = false;
    var startIndex = 0;
    var endIndex;
    var localBool = true;
    var isLast = false;
    arr.forEach(function(item,i) {
        if (item.match(el)) {
            matchedElement = item;
            if (arr.length > 1) {
                multipleElements = true;
            }
            startIndex++;
            startIndex += (item.length - item.match(/\d+$/)[0].length);
            endIndex = item.match(/\d+$/)[0].length;
            localBool = false;
            if (i === arr.length-1) {
                isLast = true;
            }
        } else if (localBool) {
            startIndex++;
            startIndex += item.length;
        }
    });
    return {
        searchVal: window.location.search,
        isMatched: isMatched,
        startIndex: startIndex,
        endIndex: endIndex,
        matchedElement: matchedElement,
        multipleElements: multipleElements,
        isLast: isLast
    }
}

function removeMatchFromUrl(str,obj) {
    var searchIndex = str.match(obj.matchedElement)['index'];
    str = str.split('');
    if (obj.multipleElements) {
        if (str[searchIndex-1] === '?') {
            str.splice(searchIndex,obj.matchedElement.length+1);
        } else {
            str.splice(searchIndex-1,obj.matchedElement.length+1);
        }
    } else {
        str.splice(searchIndex-1,obj.matchedElement.length+1);
    }
    str = str.join('');
    return str;
}

function hideShowButtons(search,btnClass,lastNumber) {
    var searchObj = parseSearchQuery(search);
    var btnNext = $('.' + btnClass + '[data-type="more"]');
    var btnPrev = $('.' + btnClass + '[data-type="less"]');
    if ( (!$('.jsLastPagIndex').length && btnClass === 'jsShowMoreLessReviewBtn') ||
         (!$('.jsLastPhotoIndex').length && btnClass === 'jsShowMoreLessPhotoBtn') ||
         (!$('.jsLastCaseIndex').length && btnClass === 'jsShowMoreLessCaseBtn') ||
         (!$('.jsLastManageVideoIndex').length && btnClass === 'jsShowMoreLessManageVideoBtn') ||
         (!$('.jsLastManagePhotoIndex').length && btnClass === 'jsShowMoreLessManagePhotoBtn') ||
         (!$('.jsLastManageCaseIndex').length && btnClass === 'jsShowMoreLessManageCaseBtn') ||
         (!$('.jsLastManageArticleIndex').length && btnClass === 'jsShowMoreLessManageArticleBtn') ||
         (!$('.jsLastManageProcedureIndex').length && btnClass === 'jsShowMoreLessManageProcedureBtn') ||
         (!$('.jsLastManageCouponIndex').length && btnClass === 'jsShowMoreLessManageCouponBtn') ||
         (!$('.jsLastAddReviewRatingIndex').length && btnClass === 'jsLastAddReviewRatingIndex') ||
         (!$('.jsLastManageReviewIndex').length && btnClass === 'jsShowMoreLessManageReviewBtn') ||
         (!$('.jsLastManageQaAIndex').length && btnClass === 'jsShowMoreLessManageQaABtn') ||
         (!$('.jsLastManageNewsIndex').length && btnClass === 'jsShowMoreLessManageNewsBtn') ||
         lastNumber === 1) {
        btnPrev.addClass('hidden');
        btnNext.addClass('hidden');
        return;
    }
    if (!(searchObj.isMatched) || searchObj.matchedElement.match(/\d+$/)[0] === 1) {
        btnPrev.addClass('hidden');
        btnNext.parent().addClass('col-xs-12').removeClass('col-xs-6');
        btnNext.removeClass('hidden');
    } else if (searchObj.isMatched) {
        btnNext.parent().addClass('col-xs-6').removeClass('col-xs-12');
        btnPrev.parent().addClass('col-xs-6').removeClass('col-xs-12');
        btnPrev.removeClass('hidden');
        btnNext.removeClass('hidden');
        if (searchObj.matchedElement.match(/\d+$/)[0] === lastNumber) {
            btnNext.addClass('hidden');
            btnPrev.parent().addClass('col-xs-12').removeClass('col-xs-6');
        }
    }
}


function fixedNavBarTabsScrolling() {
    var addMargin = $('.global-section').hasClass('dentist-management-page') ? 0 : 180;
    var topWindow = $(this).scrollTop() - addMargin;

    $('.tab-content-entry').each(function(i,item){
        var elTop = $(item).position().top;
        var elBtm = $(item).position().top + $(item).innerHeight();

        if ( topWindow > elTop && topWindow < elBtm) {
            $('.jsMainNavItem').removeClass('active');
            $('[data-id="' + $(item).attr('id') + '"]').addClass('active');
        }
    })
}

function showMenuTab(id,that) {

    /* SHOW HIDE BLOCKS ON HOME / MANAGEMENT PAGE */
    if ($('.global-section').hasClass('dentist-location') || $('.global-section').hasClass('dentist-management-page')) {
        $('#' + id).siblings().not(".to-top-button").hide();
        $('#' + id).show();

        if (id === 'jsMainNavTab41') {
            $('#mainManageFaqWrapper').find('.materialize-textarea').trigger('autoresize');
        }
    } else {
        gtag('event', 'page_view', {
            'send_to': 'UA-31019763-2',
            'block_id': id
        });
    }

    that.parent().siblings().find(".jsMainNavItem").removeClass("active");
    that.addClass("active");

    try {
        uploadDentistForm()
    } catch (TypeError) {
        console.log('Error - uploadDentistForm')
    }

    if (Cookies.get('scrollTo')) {
        window.data_id = '';
        return;
    }
    /* CHECK MOBILE OFFSET */
    var offsetW = ( $(window).width() < 768 ) ? 10 : 70;
    /* SCROLL TO TAB IF NOT 1ST TAB */
    if ( !(window.data_id === 'jsMainNavTab0')) {
        $('html,body').animate({
            scrollTop: $('#'+id).offset().top - offsetW},
            'slow', function() {
        })
    }
    window.data_id = '';
}

function getDentistsTop() {
    location.href += '&top-dentists';
}

function getDentistsNews() {
    location.href += '&dental-news';
}

function changeMetaAndTitle(tab_name) {
    $('title').text('My Practice: ' + tab_name.trim() );
    $("meta[name='description']").attr('content', 'Practice management: ' + tab_name.trim());
    $("meta[name='author']").attr('content', 'Practice management: ' + tab_name.trim());
}

function makeRequired(elem, id) {

    var currentVal = $(elem).val().trim().length > 0;
    var relatedVal = $('#' + id).val().trim().length > 0;

    if ((currentVal && !relatedVal) || (!currentVal && relatedVal)) {
        $(elem).prop('required', true);
        $('#' + id).prop('required', true);
    } else if (!currentVal && !relatedVal) {
        $(elem).prop('required', false);
        $(elem).next('.parsley-errors-list').hide();
        $(elem).removeClass('parsley-error');
        $('#' + id).prop('required', false);
        $('#' + id).next('.parsley-errors-list').hide();
        $('#' + id).removeClass('parsley-error');
    }
}

$(document).ready(function() {
    if ( $('#wrongFrame').length ) {
        Cookies.set('previousUrl', window.location.href, { expires: 1, path: "/" });
    } else {
        $(window).bind('beforeunload',function(e){
            Cookies.set('previousUrl', window.location.href, { expires: 1, path: "/" });
        });
    }
});

function leftMenuClick(element) {
    $('.jsMainNavItem').removeClass('active');
}

// if ( window.parent.document.getElementById('#wrongFrame') ) {
//     window.parent.document.getElementById('#wrongFrame').innerHTML = '';
// }

function reviewTextUpdate(review_id) {
    var form = $('#reviewTextUpdateModerate');
    var form_parsley = form.parsley();
    var smiles = generateValidJson();

    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);

        if (typeof $('#editReviewHygiene').val() !== "undefined") {
            formData.append('hygiene_review', $('#editReviewHygiene').val());
        }
        if (typeof $('#editReviewFrontDesk').val() !== "undefined") {
            formData.append('front_desk_review', $('#editReviewFrontDesk').val());
        }
        if (typeof $('#hygiene_time').val() !== "undefined") {
            formData.append('hygiene_time', $('#hygiene_time').val());
        }
        formData.append('values', JSON.stringify(smiles));
        formData.append('duration', $('#treatmentDuration').val());
        $.ajax({
            type: "POST",
            url: location.origin + "/moderator/" + review_id + "/edit/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                if (result.success) {
                    toastr.success('Review was successfully updated!');
                    setTimeout(function(){window.location.href='/moderator/' }, 1500);
                }
            },
            error: function (e) {
                toastr.error('Unknown error!');
                setTimeout(function(){ window.location.reload() }, 1000);
            }
        });
    }
}

function clickToEnd(e) {
    var searchParams = new URLSearchParams(location.search);
    e.data['current_step'] = searchParams.get('rating');
    sendRatingValue(e);
}

function dentistRegistration() {
    $('#anonLikeModal').modal('show');
    localStorage.setItem('dentist-registration', true);
}

function getDentistCoupons(url) {
    var url_request = $('#coupon-url').attr('data-url');
    if (url) url_request = url;
    if (getUrlParameter('coupon') && !url) {
        url_request = url_request + '&coupon=' + getUrlParameter('coupon')
    }
    $.ajax({
        method: "GET",
        url: url_request,
        success: function (data) {
            if (url) {
                window.history.pushState('', '',  window.location.origin + location.pathname + '?'+url.split('?')[1]);
            }
            $('#jsMainNavTab107').html(data);
            window.scrollTo(0, 0);
        }
    });
    window.onpopstate = function(event) {
        if ( (event.state && event.state.counter) || $('.coupons-class').hasClass('active') ) {
            window.location.reload();
        }
    };
}

function handleTextAreaPaste(e) {
    e.preventDefault();
    toastr.info('To paste from another source please use Quote feature below.');
}

function openContactSupport(slug) {
    Cookies.remove('navTabMemory', {path: window.location.pathname});
    location.href = location.origin + '/' + slug + '/manage/#contact_support'
}

function enableSummernote (elements) {
    elements.each(function(index, elem) {
        if (!elem.id) return;

        if (elem.id === 'summernoteNews' || elem.id.startsWith('news-content-')) {

            $('#' + elem.id).summernote('destroy');
            $('#' + elem.id).summernote({
                minHeight: 150,
                focus: true,
                toolbar: [
                    ['style', ['fontname', 'fontsize', 'bold', 'italic', 'underline', 'color']],
                    ['para', ['style', 'ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    ['insert', ['link']]
                ],
                callbacks: {
                    onKeydown: function (e) {
                        var t = e.currentTarget.innerText;
                    },
                    onPaste: function (e) {
                        var t = e.currentTarget.innerText;
                        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
                        var plainText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

                        e.preventDefault();

                        if (bufferText.length) {
                            document.execCommand('insertHtml', false, bufferText);
                        } else if (plainText.length) {
                            document.execCommand('insertText', false, plainText);
                        }
                    }
                }
            });
        } else {
            $('#' + elem.id).summernote('destroy');
            $('#' + elem.id).summernote({
                minHeight: 150,
                focus: true,
                toolbar: [
                    ['style', ['fontname', 'fontsize', 'bold', 'italic', 'underline', 'color']],
                    ['para', ['style', 'ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    ['insert', ['link']]
                ]
            });
        }
        // $('#' + elem.id).parent().append( $('#' + elem.id) );
        // $('#' + elem.id).trigger('autoresize');
    });
}


/* BOOKING WITH COUPON */
function UseCoupon(button){
    var slug = $(button).attr('data-dentist');
    window.location = location.origin + '/booking/' + slug +  '?coupon=' + $(button).attr('data-id') + '#jsMainNavTab3_anch';
}

$(document).ready(function() {
    $('#createNewsSubmit').click(addNews);

    if (location.pathname === '/dental-news/create/') {
        setTimeout(function () {
            $('#newsTitle').focus();
        }, 1000);
    }

     $('#newsBody').keyup(function () {

        var parsleyErrors = $('#parsley-id-7');

        if (parsleyErrors.length) {
            parsleyErrors.hide();
        }

        var text_length = $(this).val().length;
        var counterElementWrapper = $('#newsBody-count');
        var counterElement = counterElementWrapper.find('span');

        var errorsWrapper = $('#customBodyErrors');
        var errorsWrapper100 = errorsWrapper.find('.shorter100');
        var errorsWrapper3000 = errorsWrapper.find('.longer3000');

        if (!text_length) {
            counterElementWrapper.hide();
            errorsWrapper100.hide();
            errorsWrapper3000.hide();
            counterElement.text('');
            errorsWrapper.hide();
        } else {
            errorsWrapper.show();
            counterElementWrapper.show();
            counterElement.text(text_length);
        }

        if (text_length < 50) {
            errorsWrapper100.show();
            errorsWrapper3000.hide();
        } else if (text_length >= 50 && text_length < 3000) {
            errorsWrapper100.hide();
            errorsWrapper3000.hide();
        } else if (text_length >= 3000) {
            errorsWrapper100.hide();
            errorsWrapper3000.show();
        }
    });


    $('.jsTelClickHandler').each(function (index, elem) {
        $(elem).on('click', function(){
            var $this = $(this);
            var hrf = $(this).attr('data-href');
            var hrf_split = hrf.split('/');
            var slug = hrf_split[hrf_split.length - 1].split('#')[0];
            var url = '/phone-click/' + slug;

            if ( $this.hasClass('jsNotLinkClick') ) {
                $this.find('.tel-limited').css('max-width','none');
                $this.find('.tel-limited').next().css('display','none');
            }

            $.ajax({
                type: "POST",
                url: url,
                dataType : "json",
                data: {'csrfmiddlewaretoken': window.csrf_token},
                success: function (response) {
                    if ( !$this.hasClass('jsNotLinkClick') ) {
                        console.log(1);
                        location.href = hrf;
                    }
                },
                error: function(jqXHR, status, errorText) {
                    console.log(status, errorText);
                }
            });
        });

    });

    $('#cancel').click(function () {
        var url = '/contact/update-practice';
        $.ajax({
            type: "POST",
            url: url,
            dataType : "json",
            data: JSON.stringify({'type' : 'cancel'}),
            success: function (response) {
                if (response.status === 404) {
                   setTimeout(function(){ window.location.reload() }, 1000);
                }
            },
            error: function(jqXHR, status, errorText) {
                console.log(status, errorText);
            }
        });
    });

    $('#updatePracticeInfo').click(function (e) {
         e.preventDefault();
         var checkedValue = $('.checkboxes:checked');
         if (!checkedValue.length) {
            toastr.error('Please choose which address update');
            return;
         }
         if (!$('#terms').is(':checked')) {
            toastr.error('Please check agreament!');
            return;
         }

         var temp_a = [];
         for (var i = 0; i < checkedValue.length; i++ ) {
              var att = $(checkedValue[i]).attr('data-type');
              var data = $('div[data-type=' + att +']');
              var new_info = {};

              new_info.address = $(data).find('input[name="address"]').val();
              if (!new_info.address.length) {
                  toastr.error('Please input correct address!');
                   return;
              }
              new_info.practice = $(data).find('input[name="practice"]').val();
              new_info.website = $(data).find('input[name="website"]').val();
              new_info.phone = $(data).find('input[name="phone"]').val();
              temp_a.push(new_info);
         }
         var list_of_data = { 'type' : 'update', 'data': temp_a, 'slug': $('#slug').val()};

        var url = '/contact/update-practice';
        $.ajax({
            type: "POST",
            url: url,
            dataType : "json",
            data: JSON.stringify(list_of_data),
            success: function (response) {
                if (response.status === 404) {
                   toastr.error('User not found. Please try another listing url!');
                } else {
                     toastr.success('Your address successfully updated!');
                     setTimeout(function(){ window.location.reload() }, 1000);
                }
            },
            error: function(jqXHR, status, errorText) {
                console.log(status, errorText);
            }
        });

    });

    $('#findMe').click(function (e) {
        e.preventDefault();
        var url = '/contact/update-practice';
        var data = {
            'type': 'find',
            'slug': $('#slug').val()
        };

        $.ajax({
            type: "POST",
            url: url,
            dataType : "json",
            data:  JSON.stringify(data),
            success: function (response) {
                console.log(response.new_address);
                if (response.status === 200) {
                    $('.address-form').css('display', 'block');
                    $('#updatePracticeInfo').removeAttr('disabled','disabled');

                    $('#old-address').val(response.old_address.address);
                    $('#second-address').val(response.old_address.address_second);
                    $('#old-name').val(response.old_address.name);
                    $('#old-practice').val(response.old_address.practice);
                    $('#old-phone').val(response.old_address.phone);
                    $('#old-website').val(response.old_address.website);

                    $( "#new-address").empty();

                    $(response.new_address).each(function (i, value) {

                        var id = i + 1;

                        $( "#new-address").append(
                            '<div class="form-group">' +
                               '<input name="address-checkbox" id="info-' + id +'" class="material-input checkboxes" data-type="info-' + id +'" type="checkbox" required>  '+
                                '<label for="info-' + id +'"> Address '  + id  + ' </label>' +
                            '</div>' +
                            '<div class="form-group" data-type="info-' + id +'">' +
                                '<div class="input-field">' +
                                    '<input id="address" name="address" type="text" class="material-input" value="' + value.address + '" disabled>' +
                                    '<label for="address">Address</label>' +
                                '</div>' +
                                '<div class="input-field">' +
                                    '<input type="text" id="practice" name="practice" value="' +  value.practice + '" class="material-input">' +
                                    '<label for="practice">Practice</label>' +
                                '</div>' +
                                '<div class="input-field">' +
                                    '<input id="phone" type="text" name="phone" value="'+  value.phone +'" class="material-input">' +
                                    '<label for="phone">Phone</label>' +
                                '</div>' +
                                '<div class="input-field">' +
                                     '<input id="website" name="website" type="text" class="material-input" value="' +  value.website + '">' +
                                    '<label for="website">Website</label>' +
                                 '</div>' +
                            '</div>');

                        $('label').addClass('active');
                    });
                    if (response.new_address.length === 1) {
                        $('#info-1').prop('checked', true);
                    }
                } else if (response.status === 404) {
                     if (response.message) {
                        toastr.error(response.message );
                    } else {
                        toastr.error('User not found. Please try another listing url from our site!');
                    }
                }
            },
            error: function(jqXHR, status, errorText) {
                console.log(status, errorText);
            }
        });
    });



    $('#clickToEndReview').click({'option': 'clickToEnd'}, clickToEnd);
    $('#loginToModerator').click(loginToModerator);
    if ($('#coupon-url').length) {
        getDentistCoupons();
    }

    $('select.material-select').material_select();

    $('#cancellation-instructions').click(function () {
        var contactForm = $('#cancellation-instructions-form');
        if (contactForm[0].checkValidity()) {
            $(".throbber-wrapper").show();
        }
    });

    $('.jsIndividReviewRatingSmiles').closest('.smile-rating-module').each(function (index, el) {
        if ($(el).attr('data-groupindex')) {
            last = parseInt($(el).attr('data-groupindex'));
        } else {
            $(el).attr('data-groupindex', last + 1);
            last++;
        }
    });


    $("#checkedAll").click(function(){
       $.each($('.messageCheckbox'), function (index, el) {
               $(el).prop('checked', true);
       });
        $(this).addClass('hidden');
        $('#unCheckedAll').removeClass('hidden');
    });

    $("#unCheckedAll").click(function(){
       $.each($('.messageCheckbox'), function (index, el) {
               $(el).prop('checked', false);
       });
        $(this).addClass('hidden');
        $('#checkedAll').removeClass('hidden');
    });

    /* REDIRECT FUNCTIONALITY */
    function getNewSiteUrl() {
        var url = location.href.split('/');
            var newUrl = [];
            for (var index in url) {
                if (index > 2) {
                    newUrl.push(url[index])
                }
            }
        newUrl.splice( 0, 0, window.newSiteURL );
        location.href = newUrl.join('/');
    }

    if (window.showWelcomModal) {
        $('.welcomeModal').modal({backdrop:'static',keyboard:false, show:true});
        $('.welcomeModal').find('button').click(function () {
            getNewSiteUrl();
        });
        $("body").find(".welcomeModal").click(function () {
            if($(event.target).is(".welcomeModal")) getNewSiteUrl()
        })
    }

    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": true,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
      "onclick": null,
      "showDuration": "150",
      "hideDuration": "300",
      "timeOut": "2500",
      "extendedTimeOut": "2000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "slideDown",
      "hideMethod": "slideUp",
    };

    $(document).on("click", ".jsToTopBtn", function () {
        $('html,body').animate({
                scrollTop: 0
            },
            'fast');
    });

    /* IOS SPECIFIC CODE FOR MODALS AND BUTTONS */
    if( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
        var scrollTopParam = 0;
        $('.modal').on('show.bs.modal', function() {
            scrollTopParam = $(window).scrollTop();

            setTimeout(function () {
              $(window).scrollTop(1);
              $('.modal')
                  .addClass('modal-ios');
              $('html,body').css('overflow','hidden');
            }, 0);
        });
        $('.modal').on('hide.bs.modal', function() {
            setTimeout(function () {
              $(window).scrollTop(scrollTopParam);
              scrollTopParam = 0;
              $('.modal')
                  .removeClass('modal-ios');
              $('html,body').css('overflow','visible');
            }, 0);
        });

        if ( $('.jsMobileDownloadBtns').length ) {
            $('.jsMobileDownloadBtns .is_android').hide();
        }
    }

    /* IOS SPECIFIC CODE FOR BUTTONS */
    if( navigator.userAgent.match(/Android/i) ) {
       if ( $('.jsMobileDownloadBtns').length ) {
            $('.jsMobileDownloadBtns .is_ios').hide();
        }
    }


    /* FIXED NAV LEFT MENU - ON DIFFERENT PAGES */
    if ( $('.fixed-nav').length && $(window).width() > 1199  ) {
        $(window).on('scroll',function() {
            if ( $(window).scrollTop() > 170 ) {
                $('.fixed-nav').css({'position':'fixed','top':'65px'});
            } else {
                $('.fixed-nav').css({'position':'absolute','top':'auto'})}
        });
    }

    if ( $('.__jsAddReviewWrapperCancel').length > 0 ) {
        $('a[href]').on('click',function(e) {
            if ( !( $(this).parents('.__jsAddReviewWrapperCancel').length > 0) ) {
                e.preventDefault();
                e.stopPropagation();
                $('#cancelLinkClickReview .jsErroBtn').attr('data-href', e.currentTarget.href);
                $('#cancelLinkClickReview').modal('show');
            }
        });
         $('#cancelLinkClickReview .jsErroBtn').click(function () {
             var redirectTo = $(this).attr('data-href');

             if (redirectTo === 'javascript:void(0);') {
                 redirectTo = '/' + $('#dentist-slug').val();
             }

             $.ajax({
                method: "POST",
                url: '/reviews/api/cancel-post-review/' + $('#dentist-slug').val(),
                dataType: "json",
                data: {'review_id': getUrlParameter('review_id'),
                       'step': getUrlParameter('rating'),
                },
                success: function (response) {
                    location.href = redirectTo;
                }
            });
        });
    }
    BindShareButton();
    /* TEXT AREA RESIZES AFTER PAGE LOAD */
    $('.materialize-textarea').trigger('autoresize');
});



// DELETE USER POST //
function deleteUserPost(news_id, title_slug) {
    toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteUserPostYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteUserPostNo'>No</button></div>", 'Are you sure you want to delete this news?',
    {
        showDuration: dialogOpenTime,
        hideDuration: dialogCloseTime,
        timeOut: 5000,
        extendedTimeOut: 5000,
        closeButton: false,
        allowHtml: true,
        onShown: function(toast) {
             $('#deleteUserPostYes').click({'news_id': news_id, 'title_slug': title_slug}, ajaxDelUserPost);
             $('#deleteUserPostNo').click(function(){
                toastr.clear();
            });
        }
    });
}

// DELETE USER POST AJAX REQUEST //
function ajaxDelUserPost(e) {
    var post_data = {
        'csrfmiddlewaretoken': window.csrf_token,
        'delete': true
    };

    var url_link = url_link = "/dental-news/" + e.data.news_id + "/";

    if (e.data.title_slug) {
        url_link += e.data.title_slug + "/";
    }

    $.ajax({
        type: "POST",
        url: url_link,
        dataType: 'json',
        data: post_data,
        success: function(result) {
            if (result.success) {
                toastr.success('News item deleted!');
                setTimeout(function(){ location.reload() }, reloadTimeOut);
            }
            else {
                toastr.error('Unknown error!');
                location.reload();
            }
        }
    });
}

// TOGGLE USER POST //

function toggleUserPost() {
    $(".user-toggle").toggle("blind");
    $('html, body').animate({
        scrollTop: ($('#news-item-edit-form').offset().top - 68)
    },500);
}

// UPDATE USER POST AJAX REQUEST //

function ajaxUpdateUserPost(news_id, title_slug) {
    var form = $('#user-update-news-form');
    var form_parsley = form.parsley();
    form_parsley.validate();

    if (form_parsley.isValid()) {
        loader.show();

        var rawFormData = form.serializeArray();
        var formData = new FormData();

        rawFormData.forEach(function (element) {
            if (element.name !== 'photo') {
                formData.append(element.name, element.value);
            }
        });
        formData.append('photo', files[0]);
        formData.append('csrfmiddlewaretoken', window.csrf_token);
        formData.append('update', true);
        var url_link = "/dental-news/" + news_id + "/";

        if (title_slug) {
            url_link += title_slug + "/";
        }

        $('#user-update-news-button').prop('disabled', true);
        $.ajax({
            type: "POST",
            url: url_link,
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#user-update-news-button').prop('disabled', false);
                if (result.success) {
                    toastr.success('News item updated!');
                    if (result.slug) {
                       setTimeout(function(){ location.replace("/dental-news/" + news_id + "/" + result.slug + "/") }, 1250);
                    } else {
                        setTimeout(function(){ location.replace("/dental-news/" + news_id + "/") }, 1250);
                    }
                }
                else {
                    toastr.error('Unknown error!');
                    setTimeout(function(){ location.reload() }, 1250);
                }
            }
        });
    }
}