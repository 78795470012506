var facebookAPI = 'facebook';
var googleAPI = 'google';
var auth2;
var reRequest = false;
var api_key = window.fb_api_key;
var google_api_key = window.google_api_key;

var login_type = 'login';
var signup_type = 'signup';
var like_type = 'like';
var comparison_type = 'comparison';
var post_type = 'post';
var latest_news = 'news';

window.fbAsyncInit = function() {
    FB.init({
        appId      : api_key,
        cookie     : true,
        xfbml      : true,
        oauth      : true,
        version    : 'v2.8'
    });
    FB.AppEvents.logPageView();
};

var start = function () {
    gapi.load('auth2', function() {
        auth2 = gapi.auth2.init({
            client_id: google_api_key,
            scope: 'profile'
        })
    });
};

(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


function agreeSocialRegistration(userAPI) {
    $('#termsModal').modal('hide');
    var login = location.href.indexOf('post_likes_review/') === -1;
    if (userAPI === facebookAPI) {
        FB.api('/me?fields=id,name,email,first_name,last_name,picture', function(response) {
            response.csrfmiddlewaretoken = window.csrf_token;
            response.avatar_url = response.id ? 'https://graph.facebook.com/' + response.id + '/picture?width=400' : '';
            response.reviewLikePost = $("#post-like-review").length && $("#post-like-review").val() && $("#post-like-review").val() === 'true' ? true : false;
            redirectAfterLogin(response, userAPI, login);
    });
    } else {
        var response = mapGoogleResponse(auth2.currentUser);
        response.csrfmiddlewaretoken = window.csrf_token;
        redirectAfterLogin(response, userAPI, login);
    }
}

function checkLoginState(reviewLikePost, usedAPI, type, postWithFacebook) {
    postWithFacebook = typeof postWithFacebook !== 'undefined' ? postWithFacebook : false;
    $('#postLikeModal').modal('hide');
    $('#loginModal').modal('hide');
    if (usedAPI === facebookAPI) {
        FB.login(function(response) {
            if (response.authResponse) {
                FB.api('/me?fields=id,name,email,first_name,last_name,picture', function(response) {
                    response.avatar_url = response.id ? 'https://graph.facebook.com/' + response.id + '/picture?width=400' : '';
                    response.csrfmiddlewaretoken = window.csrf_token;
                    response.reviewLikePost = reviewLikePost;
                    checkFirstLogin(response, usedAPI, type, postWithFacebook);
            });
            }
        }, {scope: 'email,public_profile'});
    } else {
        if (!auth2.isSignedIn.get()) {
            auth2.signIn().then(function(googleUser) {
                googleAuth(auth2.currentUser, reviewLikePost, usedAPI, type);
            }, function() {
                console.log('Google interactive authorise failed');
            });
        } else {
            googleAuth(auth2.currentUser, reviewLikePost, usedAPI, type)
        }
    }
}

function getPatientFacebookData() {
    $('#populateFacebookData').attr('disabled', 'disabled');
    FB.login(function(response) {
        if (response.authResponse) {
            FB.api('/me?fields=id,name,email,first_name,last_name,picture', function (response) {
                response.avatar_url = response.id ? 'https://graph.facebook.com/' + response.id + '/picture?width=400' : '';
                response.csrfmiddlewaretoken = window.csrf_token;
                if (response.first_name) {
                    $('#appoint_first_name').val(response.first_name);
                }
                if (response.last_name) {
                    $('#appoint_last_name').val(response.last_name);
                }
                if (response.email) {
                    $('#appoint_email').val(response.email);
                }
                if (response.picture.data.url) {
                    $('#facebookImage').attr('src', response.picture.data.url).parent().removeClass('hidden');
                    $('#facebookImageHidden').val(response.picture.data.url);
                }
                Materialize.updateTextFields();

                $('#populateFacebookData').removeAttr('disabled');
            });
        }
        $('#populateFacebookData').removeAttr('disabled');
    }, {scope: 'email,public_profile'});
}

function googleAuth(currentUser, reviewLikePost, usedAPI, type) {
    var response = mapGoogleResponse(currentUser);
    response.reviewLikePost = reviewLikePost;
    checkFirstLogin(response, usedAPI, type);
}

function mapGoogleResponse(currentUser) {
    var baseProfile = currentUser.Ab.w3;
    var response = {};
    if (baseProfile.ig) {
        response['name'] = baseProfile.ig;
    }
    response['first_name'] = baseProfile.ofa;
    response['last_name'] = baseProfile.wea;
    response['email'] = baseProfile.U3;
    response['avatar_url'] = baseProfile.Paa;
    response['id'] = baseProfile.Eea;
    return response
}

/* LIKE DENTIST FUNCTIONALITY */
var ratingScore = 0;
var selectedLikes = [];
var positiveTag = true;

/* SMILE RATING MOUSE HOVER UI */
function smileHoverHandler(el,right_caption) {
    el.addClass('on');
    el.prevAll().not('.rating-caption').addClass('on');
    el.nextAll().not('.rating-caption').removeClass('on');
    el.siblings().not('.smile').addClass('hidden');
    el.next().removeClass('hidden');

    if ( right_caption == 'right_caption' ) {
        el.parent().parent().find('.rating-caption').addClass('hidden');
        el.parent().parent().find('.rating-caption').eq( el.attr('data-index') - 1 ).removeClass('hidden');
    }
}

function smileClickHandled(el) {
    ratingScore = parseInt(el.attr('data-index'));
    if ( selectedLikes.length || $('#jsRatingSmiles').find('.on').length ) {
        $('.jsLikeTagRatingWrapper').show();
    } else {
        $('.jsLikeTagRatingWrapper').hide();
    }

    if ($('#jsRatingSmiles').find('.on').length <= 2.5 && $('.jsDislike').hasClass("hidden")) {
        positiveTag = false;
        selectedLikes = [];
        $('.jsLike').addClass('hidden');
        $('.jsDislike').removeClass('hidden');
        clearButtons('.jsDislike button', 'btn-danger');
        $('#likeTypeHeader').text('Dislike').attr('data-type','dislike');
    } else if ($('#jsRatingSmiles').find('.on').length > 2.5 && $('.jsLike').hasClass("hidden")) {
        positiveTag = true;
        selectedLikes = [];
        $('.jsLike').removeClass('hidden');
        $('.jsDislike').addClass('hidden');
        clearButtons('.jsLike button', 'btn-success');
        $('#likeTypeHeader').text('Like').attr('data-type','like');
    }
}

$('#jsRatingSmiles').on('mouseleave', function() {
        if (ratingScore>0) {
            var current = $('#jsRatingSmiles .smile').eq(ratingScore-1);
            smileHoverHandler(current);
        } else {
            $('.smile').removeClass('on');
            $('.rating-caption').addClass('hidden');
        }
    }
);

$('#jsRatingSmiles .smile').on('mouseenter', function() {
    smileHoverHandler($(this));
});

// Show selected smiles if user already selected some


/* SMILE RATING MOUSE CLICK UI */
$('#jsRatingSmiles .smile').on('click', function() {
    smileClickHandled($(this));
});

if(!!('ontouchstart' in window)) {
    $('#jsRatingSmiles .smile').off('click mouseleave mouseenter');
    $('#jsRatingSmiles .smile').on('mouseenter', function() {
        smileHoverHandler($(this));
        smileClickHandled($(this));
    });
}

function clearButtons(button, classToRemove) {
     $.each($('#dentist-like-tags').find(button), function (index, el) {
         $(this).removeClass(classToRemove);
         $(this).addClass('emotion');
    });
}

function likeTagHandler() {
    var smiles_count = $( '#jsRatingSmiles').find('.on').length;
    if ($(this).hasClass('emotion')) {
        if (selectedLikes.length < 15) {
            if ( smiles_count > 0 && smiles_count < 3 ) {
                $(this).addClass('emotion-negative');
            } else {
                $(this).addClass('emotion-positive');
            }
            $(this).removeClass('emotion');
            selectedLikes.push($(this).attr('data-key'));
        } else {
            $('.jsTagAlert').show();
        }
    } else if ($(this).hasClass('emotion-positive') || $(this).hasClass('emotion-negative')) {
         if (selectedLikes.length < 16) {
            $('.jsTagAlert').hide();
        }
        $(this).addClass('emotion');
        $(this).removeClass('emotion-positive emotion-negative');
        var index = selectedLikes.indexOf($(this).attr('data-key'));
        selectedLikes.splice(index, 1);
    }
    if ( selectedLikes.length || smiles_count ) {
        $('.jsLikeTagRatingWrapper').show();
    } else {
        $('.jsLikeTagRatingWrapper').hide();
    }
}

function showRegisterByEmailModal() {
    $('#postLikeModal').modal('hide');
    $('#registerByEmailModal').modal('show');
}

function showLoginModal() {
    $('#postLikeModal').modal('hide');
    $('#loginModal').modal('show');
}

function isReplyOrRemovePage() {
    return location.href.indexOf('/reviews/reply') !== -1 || location.href.indexOf('/reviews/remove') !== -1;
}

function isReviewPage() {
    return (location.href.indexOf('rating') !== -1 && getUrlParameter('rating'));
}

function isDentistRegistrationPage() {
    return location.href.indexOf('/dentist-registration') !== -1;
}

function isComparisonPage() {
    return location.href.indexOf('comparison-key') !== -1;
}

function postCompareReview(){
    var review_id = localStorage.getItem('post-compare');
    var data = {
        'csrfmiddlewaretoken': window.csrf_token,
        'review_id': review_id
    };

    $.ajax({
        method: "POST",
        url: location.origin + "/reviews/complete-comparison-key",
        dataType: "json",
        data: data,
        processData: false,
        contentType: false,
        success: function () {
            return true
        },
        error: function () {
            return false
        }
    });
    localStorage.removeItem('post-compare');
}

function postLikesReview(response) {
    $('#post-likes-review').attr('disabled', true);
    loader.show();
    var isPositive = $('#likeTypeHeader').attr('data-type') === 'like';
    var data = {
        'selectedLikes' : selectedLikes.join(','),
        'csrfmiddlewaretoken': window.csrf_token,
        'is_positive': isPositive,
        'rating': $('#jsRatingSmiles').find('.on').length
    };
    loader.show();
    $.ajax({
            method: "POST",
            url: "/reviews/post_likes_review/" + $('#dentist-slug').val(),
            dataType: "json",
            data: data,
            success: function (result) {
                if(result.addUserName) {
                    $('#reviewId').val(result.reviewId);
                    $('#postAnonymusModal').modal('show');
                    $('#post-likes-review').attr('disabled', false);
                    loader.hide();
                } else if (result.redirect_to) {
                    location.href = '/' + result.slug + '#read_review' ;
                } else {
                    postRedirect(response.is_new);
                }
            },
            error: function () {
                location.reload();
            }
        }
    );
}

function postRedirect(isNewUser) {
    if (checkAddReviewAuthRedirect()) {
        location.reload();
    } else if (isNewUser){
        if (localStorage.getItem('post-face')){
            localStorage.setItem('post-face-after-redirect', window.location.pathname);
            postFaceGalleryPhoto();
            setTimeout(function () {
                location.href = '/user-role/';
            }, 500);
        } else {
            location.href = '/user-role/';
        }
    } else if (localStorage.getItem('post-face')) {
        postFaceGalleryPhoto(true)
    } else if (location.search) {
        setTimeout(function () {
            location.href = location.pathname
        }, 1000);
    }
    // else if (isFacebookConnect) {
    //     location.href = location.pathname + '?review=1#mainReviewWrapper';
    // } else {
    //     location.href = location.pathname + '?review=1#mainReviewWrapper';
    // }
}

function postSocialLogin(response, usedAPI, type) {
    response.usedAPI = usedAPI;
    response.path = location.pathname;

    if (type === comparison_type)
        response.comparison = true;

    if (getUrlParameter('rating'))
        response.rating = getUrlParameter('rating');

    if (getUrlParameter('review_id')) {
        response.review_id = getUrlParameter('review_id');
    } else if(localStorage.getItem('post-compare')) {
        response.review_id = localStorage.getItem('post-compare');
        localStorage.removeItem('post-compare');
    }

    if (Cookies.get('unpostedNewsItem') && location.href.indexOf('/dental-news/create/') !== -1) {
        response.postLatestnews = true;
    }

    $('.is-facebook-button').attr('disabled', 'disabled');

    if (response.email) {

        $.ajax({
                method: "POST",
                url: "/social-login/",
                dataType: "json",
                data: response,
                success: function (result) {
                    $('.is-facebook-button').attr('disabled');
                    response.is_new = result.is_new;

                    if (response.contactRedirect){
                        location.href = location.origin + '/contact/';
                    } else if (result.authenticated) {
                        response.csrfmiddlewaretoken = result.new_csrf_token;

                        if (isReviewPage() || isComparisonPage()) {
                            location.href = result.posted_review ? location.origin + '/' + $('#dentist-slug').val() + '#read_review' : location.href;
                        } else if (isReplyOrRemovePage()) {
                            location.reload();
                        } else if (!response.postLatestnews && !localStorage.getItem('post-face') && type === login_type && !isComparisonPage()) {
                            if (result.redirect_to_payment) {
                                location.href = result.redirect_to_payment;
                            } else {
                                location.href = result.slug ? location.href = location.origin + '/' + result.slug + '/manage/' : location.pathname;
                            }
                        } else if (response.postLatestnews) {
                            ajaxRequestPostLastNews();
                        } else if (response.reviewLikePost && !localStorage.getItem('post-face') && type === like_type) {
                            postLikesReview(response)
                        } else if (!response.reviewLikePost && !localStorage.getItem('post-face') && result.is_dentist_page && response.usedAPI === facebookAPI) {
                            postFacebookPhoto(response)
                        } else if (localStorage.getItem('post-face')) {
                            postFaceGalleryPhoto(true)
                        } else if (isDentistRegistrationPage()) {
                            setRole(true);
                        } else if (result.is_new){
                            location.href = '/user-role/';
                        } else {
                            location.reload()
                        }
                    }
                }
            }
        );
    }
}

function redirectAfterLogin(response, usedAPI, type) {
    if (!response.email && !reRequest && usedAPI === facebookAPI) {
        reRequestToFacebook(response)
    } else {
        if (!window.user_is_authenticated) {
            if (type === login_type) {
                toastr.success('Login successful.');
            } else if (type === signup_type) {
                toastr.success('Successfully signed up.');
            } else if (type === like_type) {
                toastr.success('Like was successfully posted .');
            } else if (type === post_type){
                toastr.success('Successfully posted.');
            } else if (type === comparison_type) {
                toastr.success('Comparison key was successfully posted.');
            }
        }
        setTimeout(function() {
            postSocialLogin(response, usedAPI, type);
        }, 300)
    }
}

function checkFirstLogin(response, userAPI, type, postWithFacebook) {
    if (!response.email) {
        reRequest = false;
    }
    $.ajax({
            method: "GET",
            url: "/social-login/",
            dataType: "json",
            data: response,
            success: function (result) {
                if (result.contactRedirect) {
                    location.href = location.origin + '/contact/';
                } else if (!result.exists && !postWithFacebook) {
                    $('#anonLikeModal').modal('hide');
                    $('#termsModal').modal();
                    $('#'+userAPI).removeClass('hide');
                    $("#post-like-review").val(response.reviewLikePost);

                    var dentist_url = location.origin + '/' + $('#dentist-slug').val();

                    if (location.href.indexOf(dentist_url) !== -1) {
                        localStorage.setItem('redirect-role', dentist_url);
                    }

                } else {
                    redirectAfterLogin(response, userAPI, type);
                }
            }
        }
    );
}

function facebookCloseImage(){
    $('#facebookImageClose').parent().addClass('hidden');
    $('#facebookImageClose').parent().find('img').attr('src','');
    $('#facebookImageHidden').hide();
    $('#facebookImageHidden').val('');
}

function checkAddReviewAuthRedirect(){
    return localStorage.getItem('addReviewModalRedirect');
}

function postFacebookPhoto(response) {
    response.dentist = location.pathname;
    response.connected_by_facebook = true;
    $.ajax({
            method: "POST",
            url: "/reviews/post-facebook-avatar/",
            dataType: "json",
            data: response,
            success: function (result) {
                postRedirect(response.is_new);
            }
        }
    );
}

function agreeUserReview(review_id, is_agree, is_comparison) {
    is_comparison = typeof is_comparison !== 'undefined' ? is_comparison : false;

    var data = {
        'review_id' : review_id,
        'is_agree': is_agree,
        'csrfmiddlewaretoken': window.csrf_token,
        'comparison': is_comparison,
        'slug': $('#dentist-slug').val()
    };

    $.ajax({
            method: "POST",
            url: "/reviews/agree-review/",
            dataType: "json",
            data: data,
            success: function (result) {
                $('#activeAgreeButton_' + review_id).hide();
                $('#deactivateAgreeButton_' + review_id).show();
                if (result.created) {
                    var agree = $('#agreeCountID_' + review_id).find('span');
                    var disagree = $('#disagreeCountID_'+ review_id).find('span');
                    if (is_agree) {
                        updateReviewVotes(agree, 'green')
                    } else {
                        updateReviewVotes(disagree, 'red')
                    }
                }
            }
        }
    );
}


function updateReviewVotes(field, color) {
    if (field.text()) {
        field.text(parseInt(field.text()) + 1).css('color', color);
    } else {
        field.text(1).css('color', color);
    }
}


function likeCase(case_id) {

    var data = {
        'object_id' : case_id,
        'csrfmiddlewaretoken': window.csrf_token,
        'slug': $('#dentist-slug').val()
    };

    $.ajax({
            method: "POST",
            url: "/reviews/like-case/",
            dataType: "json",
            data: data,
            success: function (result) {
                $('#activeLikeButton_' + case_id).hide();
                $('#deactivateLikeButton_' + case_id).show();
                if (result.liked) {
                    var like = $('#likeCountID_' + case_id).find('span');
                    if (like.text()) {
                        like.text(parseInt(like.text()) + 1);
                    } else {
                        like.text(1);
                    }
                }
            }
        }
    );
}


function reRequestToFacebook(response) {
    FB.login(function(response) {
        reRequest = true;
        FB.api('/me?fields=id,name,email,first_name,last_name,picture', function(response) {
        response.csrfmiddlewaretoken = window.csrf_token;
        postSocialLogin(response);
    });
    }, {
        scope: 'email,public_profile',
        auth_type: 'rerequest'
    });
}

function registerUser(e) {
    var form = $('#registerByEmailForm');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);

        if (getUrlParameter('rating')) formData.append('rating', getUrlParameter('rating'));
        if (getUrlParameter('review_id')) {
            formData.append('review_id', getUrlParameter('review_id'));
        } else if (localStorage.getItem('post-compare')) {
            formData.append('review_id', localStorage.getItem('post-compare'));
            formData.append('comparison', true);
        }

        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "/sign-up/",
            dataType : "json",
            data: formData,
            processData: false,
            contentType: false,
            success: function (result) {
                if (result.contactRedirect) {
                    location.href = location.origin + '/contact/';
                } else if (result.userAlreadyExist) {
                    toastr.error('User with this email already exists.')
                } else if (localStorage.getItem('post-like')) {
                    Cookies.set('navTabMemory', 'jsMainNavTab1', {expires: 1, path: localStorage.getItem('post-like')});
                    localStorage.removeItem('post-like');
                    postLikesReview(result);
                } else if (localStorage.getItem('post-compare')) {
                    postCompareReview();
                    location.href = location.origin + '/' + $('#dentist-slug').val() + '#read_review';
                } else if (Cookies.get('unpostedNewsItem') && location.href.indexOf('/dental-news/create/') !== -1) {
                    ajaxRequestPostLastNews();
                } else if (isDentistRegistrationPage()) {
                    setRole(true);
                } else {
                    var dentist_url = location.origin + '/' + $('#dentist-slug').val();
                    if (location.href.indexOf(dentist_url) !== -1)
                        localStorage.setItem('redirect-role', dentist_url);
                    postRedirect(true);
                }
            }
        });
    }
}

function loginUser(e) {
    var form = $('#emailLoginModalForm');
    var form_parsley = form.parsley();
    var login_attribute = $('#loginUser').attr('data-type');

    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);

        if (getUrlParameter('rating')) formData.append('rating', getUrlParameter('rating'));
        if (getUrlParameter('review_id')) {
            formData.append('review_id', getUrlParameter('review_id'));
        } else if(localStorage.getItem('post-compare') && login_attribute === comparison_type) {
            formData.append('review_id', localStorage.getItem('post-compare'));
            formData.append('comparison', true);
        }

        var url = $('#loginRolePatient')[0].checked ? '/login-patient/' : '/login-dentist/';
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: url,
            dataType : "json",
            data: formData,
            processData: false,
            contentType: false,
            success: function (result) {
                if (result.userDoesNotExist) {
                    toastr.error('User with this email does not exist.');
                } else if (result.notAuthUser) {
                    toastr.error('Wrong email or password.');
                } else if (result.oldAuthDentist){
                    toastr.info('Another set of login credentials for this email already exists. Please login with original credentials or register with a different email.', '', {
                        timeOut: 8000,
                        extendedTimeOut: 10000
                    });
                } else if (checkAddReviewAuthRedirect()) {
                    if (result.slug) {
                        toastr.success('Dentist login successful.');
                    } else {
                        toastr.success('Login successful.');
                    }

                    if (localStorage.getItem('post-like') && login_attribute === like_type) {
                        $('#emailLoginModal').modal('hide');
                        Cookies.set('navTabMemory', 'jsMainNavTab1', {
                            expires: 1,
                            path: localStorage.getItem('post-like')
                        });
                        localStorage.removeItem('post-like');
                        postLikesReview(result);
                    } else if (localStorage.getItem('post-compare') && login_attribute === comparison_type) {
                        $('#emailLoginModal').modal('hide');
                        postCompareReview();
                        location.href = location.origin + '/' + $('#dentist-slug').val() + '#read_review'
                    } else {
                        location.reload();
                    }
                } else if (Cookies.get('unpostedNewsItem') && location.href.indexOf('/dental-news/create/') !== -1) {
                    ajaxRequestPostLastNews();
                } else if (result.slug) {
                    toastr.success('Dentist login successful.');

                    if (!result.is_posted_from_session) {
                        if (isReplyOrRemovePage()) {
                            location.reload();
                        } else if (result.redirect_to_payment) {
                            location.href = result.redirect_to_payment;
                        } else if (localStorage.getItem('post-face')) {
                            postFaceGalleryPhoto();
                            setTimeout(function () { location.href = location.origin + '/' + result.slug + '/manage/'; }, 1000)
                        } else {
                            setTimeout(function () { location.href = location.origin + '/' + result.slug + '/manage/'; }, 1000)
                        }
                    } else {
                        location.href = location.pathname;
                    }
                } else if (localStorage.getItem('post-face')){
                    postFaceGalleryPhoto(true)
                } else {
                    toastr.success('Login successful.');
                    location.reload();
                }
            }
        });
    }
}

function logoutUser(event) {
    event.preventDefault();
    toastr.success("Logout successful.");
    var previous_url = location.href;
    if (previous_url.indexOf("rating=") !== -1 || previous_url.indexOf("/manage/") !== -1) {
        previous_url = location.pathname;
    }
    setTimeout(function() {
        location.href = '/logout/?from=' + previous_url;
    },1000)
}

function resetPassword(e) {
    var form = $('#forgotPasswordModal').find('form');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $(".throbber-wrapper").show();
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "password/forgot/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                if (result.success) {
                    $('#successReset').show();
                    setTimeout(function(){
                        location.reload()
                    }, 1500);
                }
                else {
                    toastr.error(result.message);
                }
                $(".throbber-wrapper").hide();
            }
        });
    }
}

if (!window.sessionStorage.getItem('not_first_login')) {
    $.get('/search-location/', {'current_location': window.location.search,
                                'current_location_pathname': location.pathname}, function(response) {
        if (response.status == 301) {
            window.sessionStorage.setItem('not_first_login', true);
            window.location = response.path;
        }
    });
}

function showPostWithModal(show) {
    if (show) {
        $('#postLikeModal').modal('show');
    } else {
        $('.jsContrlBtns').prop('disabled', false);
    }
}

function updateUserName(customName) {
    if (location.pathname === "/dental-news/create/") {
        return;
    }

    if (!getUrlParameter('rating')) {
        var userName = '';
        if (customName) {
            var userNameField = $('#userName').val();
            userName = userNameField ? userNameField : $('#defaultUserName').val();
        } else {
            userName = $('#defaultUserName').val();
        }
        var data = {
            'reviewId' : $('#reviewId').val(),
            'csrfmiddlewaretoken': window.csrf_token,
            'userName': userName
        };
        $.ajax({
                method: "POST",
                url: "/reviews/update_review_user_name/",
                dataType: "json",
                data: data,
                success: function (result) {
                    $('#postAnonymusModal').modal('hide');
                    if (result.slug) {
                        location.href = '/' + result.slug + '#read_review';
                    } else {
                        location.reload();
                    }
                }
            }
        );
    }
}

function updateCompareaUserName(customName) {
    var userName = '';
    if (customName) {
        var userNameField = $('#userName').val();
        userName = userNameField ? userNameField : $('#defaultUserName').val();
    } else {
        userName = $('#defaultUserName').val();
    }
    var data = {
        'reviewId' : $('#reviewId').val(),
        'csrfmiddlewaretoken': window.csrf_token,
        'userName': userName
    };
    $.ajax({
            method: "POST",
            url: "/reviews/update_compare_user_name/",
            dataType: "json",
            data: data,
            success: function (result) {
                $('#postAnonymusModal').modal('hide');
                if (result.slug) {
                    location.href = '/' + result.slug + '#read_review';
                } else {
                    location.reload();
                }
            }
        }
    );
}

function setRole(isDoctor) {
    var data = {};
    if (isDoctor) {
        data['isDoctor'] = true;
    } else {
        data['isPatient'] = true;
    }
    $.ajax({
            method: "POST",
            url: location.origin + '/user-role/',
            dataType: "json",
            data: data,
            success: function (result) {
                if (result.user_role === 2) {
                    location.href = '/dentist/registration/index/';
                } else if (result.user_role === 1) {
                    if (localStorage.getItem('post-face') && localStorage.getItem('post-face-after-redirect')) {
                        var redirectTo = localStorage.getItem('post-face-after-redirect');
                        localStorage.removeItem('post-face-after-redirect');
                        localStorage.removeItem('post-face');
                        location.href = redirectTo;
                    } else if (localStorage.getItem('reviewStep')) {
                        Cookies.set('navTabMemory', 'jsMainNavTab3', {expires: 1, path: localStorage.getItem('reviewStep')});
                        location.href = localStorage.getItem('reviewStep');
                        localStorage.removeItem('reviewStep');
                    } else if (localStorage.getItem('redirect-role')) {
                        location.href = localStorage.getItem('redirect-role');
                        localStorage.removeItem('redirect-role');
                    } else {
                        location.href = '/';
                    }
                }
            }
        }
    );
}

function RegistrationStepOne() {
    window.history.pushState({'step': 1}, "Step 1", window.location.pathname + '?step=1');
    $('#blockIndex').hide();
    $('#blockCEJ').show();
}

function RegistrationStepTwo() {
    $('#blockErrorCEJ').hide();
    var word = $("#enamel").val();
    if (word.toLowerCase() == "enamel") {
        window.history.pushState({'step': 2}, "Step 2", window.location.pathname + '?step=2');
        $('#blockCEJ').hide();
        $('#blockName').show();
    } else {
        word = $("#enamel").val(null);
        $('#blockErrorCEJ').show();
    }
}

window.onpopstate = function(e){
    var step = getUrlParameter('step');
    if (step == '1') {
        $('#blockName').hide();
        $('#blockIndex').hide();
        $('#blockCEJ').show();
        return false
    }
    if (step == '2') {
        $('#blockIndex').hide();
        $('#blockName').show();
        $('#blockCEJ').hide();
        return false
    }
    if (!step && window.location.pathname === "/dentist/registration/index/") {
        $('#blockIndex').show();
        $('#blockName').hide();
        $('#blockCEJ').hide();
    }
};


function RegistrationStepThree() {
    location.href =  '/dentist/registration/list/?search=' + $("#search-register-input").val()

}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

function postLastNews(loginType) {
    if (loginType === facebookAPI) {
        checkLoginState(false, facebookAPI, latest_news, true);
    } else if (loginType === googleAPI) {
        checkLoginState(false, googleAPI, latest_news, true);
    } else if (loginType === 'email') {
        $('#emailLoginModal').modal('show');
    }
}

function getSocialUserData(api, form_data){
    if (api === facebookAPI) {
        FB.login(function(response) {
            if (response.authResponse) {
                FB.api('/me?fields=id,name,email,first_name,last_name', function(response) {
                    response.avatar_url = response.id ? 'https://graph.facebook.com/' + response.id + '/picture?width=400' : '';
                    sendMessage(form_data, response);
                });
            }
        }, {scope: 'email,public_profile'});
    } else {
        if (!auth2.isSignedIn.get()) {
            auth2.signIn().then(function(googleUser) {
                var response = mapGoogleResponse(auth2.currentUser);
                sendMessage(form_data, response);
            }, function() {
                console.log('Google interactive authorise failed');
            });
        } else {
            var response = mapGoogleResponse(auth2.currentUser);
            sendMessage(form_data, response);
        }
    }

}

function sendMessage(form_data, response) {
    form_data.append('email', response.email);
    form_data.append('first_name', response.first_name);
    form_data.append('last_name', response.last_name);
    if (response.avatar_url)
        form_data.append('photo_url', response.avatar_url);
    ajaxRequestSendMessage(form_data);
}


$('#showLoginModal').on('click', function(){
    localStorage.setItem("showLoginPopup", true);
    $('#loginModal').modal('show');
});

$( document ).ready(function () {
    if (localStorage.getItem("showLoginPopup")) {
        $('#loginModal').modal('show');
        localStorage.removeItem("showLoginPopup");
    }
    var step = getUrlParameter('step');
    if (step == '1') {
        $('#blockIndex').hide();
        $('#blockCEJ').show();
    }
    if (step == '2') {
        $('#blockIndex').hide();
        $('#blockName').show();
    }
    if (!step && window.location.pathname === "/dentist/registration/index/") {
        $('#blockIndex').show();
    }

    $("#registerUser").click(registerUser);
    $("#loginUser").click(loginUser);
    $('#dentist-like-tags').find('button').click(likeTagHandler);
    $('#post-likes-review').click(postLikesReview);
    $('#post-like-sign-up').click(function () {
        localStorage.setItem('post-like', window.location.href);
    });

    $('#resetPassword').click(resetPassword);

    $('.jsResetAddReviewModalRedirect').click(function () {
        if (localStorage.getItem('addReviewModalRedirect')) {
            localStorage.removeItem('addReviewModalRedirect');
        }
    });

    if (location.pathname === '/') {
        localStorage.removeItem('reviewStep');
    }

    if (location.pathname.indexOf('/reviews/post_likes_review/') >= 0) {
        var splitedUrl = location.pathname.split('/');
        localStorage.setItem('reviewStep', '/' + splitedUrl[splitedUrl.length - 1] + '#read_review');
    }

    $('.jsSetAddReviewModalRedirect').click(function () {
        if (!localStorage.getItem('addReviewModalRedirect')){
            localStorage.setItem('addReviewModalRedirect', true);
        }
    });
});

