$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-review-page') ) {
        var apiUrl = '/api/likes';
        var pattern = /like/;
        var key = 'like';
        var wrapperClass = 'jsLikeKickWrapper';
        var lastIndexClass = 'jsLastLikeKickIndex';
        var buttonClass = 'jsShowMoreLessLikeKickBtn';

        function jsLikePagination() {
            var btn = $(this);
            jsInfoPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }
        hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsLikePagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-review-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetLikeKickPagination() {
    resetPagination(/like/, 'api/likes', 'like', 'jsLastLikeKickIndex', 'jsLikeKickWrapper', 'jsShowMoreLessLikeKickBtn');
}

