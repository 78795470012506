$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/photos';
        var pattern = /photo/;
        var key = 'photo';
        var wrapperClass = 'jsManagePhotoWrapper';
        var lastIndexClass = 'jsLastManagePhotoIndex';
        var buttonClass = 'jsShowMoreLessManagePhotoBtn';

        function jsManagePhotoPagination() {
            var btn = $(this);
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManagePhotoPagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-management-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetManagePhotoPagination() {
    resetManagePagination(/photo/, 'api/photos', 'photo', 'jsLastManagePhotoIndex', 'jsManagePhotoWrapper', 'jsShowMoreLessManagePhotoBtn');
}
