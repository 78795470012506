var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

function changeTabTitleAndMeta(text) {
    $('title').text(text);
    $("meta[name='description']").attr('content', text.trim());
    $("meta[name='author']").attr('content', text.trim());
}

var appointmentTitle = $("#appointment-h1-title").text();

function sendPrivateMessage(type) {
    var form = $('#send-message-form-id');
    var form_parsley = form.parsley();
    form_parsley.validate();

    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);

        if (type === facebookAPI || type === googleAPI) {
            getSocialUserData(type, formData, 'news');
        } else if (type === 'email') {
            $('#messageSendWithEmail').modal('show');
        } else {
            ajaxRequestSendMessage(formData);
        }
    }
}

function sendMessageWithEmail() {
    var modal_form = $('#modal-email-user-data-form');
    var form_parsley = modal_form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var form = $('#send-message-form-id');
        var formData = new FormData(form[0]);

        modal_form.find('input').each(function (index, elem) {
            formData.append($(elem).attr('name'), $(elem).val());
        });

        ajaxRequestSendMessage(formData);
    }
}

function ajaxRequestSendMessage(data) {
    $.ajax({
        type: "POST",
        url: location.href,
        dataType: 'json',
        data: data,
        processData: false,
        contentType: false,
        beforeSend: function () {
            loader.show();
            $(this).prop('disabled', true);
        },
        success: function(result) {
            loader.hide();
            $('#messageSendWithEmail').modal('hide');
            $(this).prop('disabled', false);
            if (result.success) {
                $('#private-message-success-id').show();
                $('#private-message-send-form-id').hide();
            }
            else {
                toastr.error('Unknown error!');
            }
        }
    });
}

function ajaxRequestPostLastNews(csrfToken) {
    if (!Cookies.get('unpostedNewsItem') || !localStorage.getItem('unpostedNewsItemData')) {
        location.reload();
    }

    var jsonFormData = JSON.parse(localStorage.getItem('unpostedNewsItemData'));
    var formData = new FormData();

    Object.keys(jsonFormData).forEach(function (key) {
        if (key !== 'photo' && key !== 'csrfmiddlewaretoken') {
            formData.append(key, jsonFormData[key]);
        }
    });
    formData.append('photo', files[0]);

    Cookies.remove('unpostedNewsItem');
    localStorage.removeItem('unpostedNewsItemData');

    if (!csrfToken || csrfToken === undefined) {
        csrfToken = Cookies.get('csrftoken');
    }

    formData.append('csrfmiddlewaretoken', csrfToken);

    loader.show();
    $.ajax({
        type: "POST",
        url: location.href,
        dataType: 'json',
        data: formData,
        processData: false,
        contentType: false,
        success: function(result) {
            loader.hide();

            if (result.success) {
                toastr.success('News item created!');
                setTimeout(function() {location.href = '/'; }, reloadTimeOut);
            }
            else {
                toastr.error('Unknown error!');
                location.reload();
            }
        }
    });
}

$(document).ready(function () {

    var review_page = getUrlParameter('review');
    if (review_page > 1) {
        Cookies.set('previousUrl', window.location.origin + window.location.pathname, {expires: 1, path: "/"});
    }

    var success = getUrlParameter('success');
    $('#registration-description').keydown(function () {
       var span = $('#registration-description-count');
       if ($(this).val() === 0) {
           span.html(1250);
       } else {
           var KeyID = event.keyCode;
           if (KeyID === 8 || KeyID === 46) {
               span.html(parseInt(span.html()) + 1)
           } else if (KeyID >= 48 && KeyID <= 90 || KeyID >= 96 && KeyID <= 105 || KeyID === 32) {
               span.html(parseInt(span.html()) - 1)
           }
       }
    });

    $('#update-description').keydown(function () {
       var span = $('#update-description-count');
       if ($(this).val() === 0) {
           span.html(1250);
       } else {
           var KeyID = event.keyCode;
           if (KeyID === 8 || KeyID === 46) {
               span.html(parseInt(span.html()) + 1)
           } else if (KeyID >= 48 && KeyID <= 90 || KeyID >= 96 && KeyID <= 105 || KeyID == 32) {
               span.html(parseInt(span.html()) - 1)
           }
       }
    });

    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-review-page') ) {

        $(window).on('scroll', checkScrollToBtmReview);

        if (success && localStorage.getItem('poppedUp')) {
            toastr.success('', 'Your appointment was successfully created', {timeOut: 5000});
            localStorage.removeItem('poppedUp');
        }

        /* CLICK ON MENU TAB IN TOP OF DENTIST PAGE */
        $(document).on("click", ".jsMainNavItem:not([href])", function () {
            var current_path = window.location.pathname;
            if (localStorage.getItem('success') && $(this).attr("data-id") == 'jsMainNavTab7') {
                localStorage.removeItem('success');
                Cookies.set('navTabMemory', $(this).attr("data-id"), {expires: 1, path: current_path});
                resetAllPagination($(this).attr("data-id"));
                window.location.replace(current_path);
            } else {
                var id = $(this).attr("data-id");
                Cookies.set('navTabMemory', id, {expires: 1, path: current_path});

                if (window.location.search) {
                    resetAllPagination(id);
                }
                /* SCROLL TO MENU ITEM */
                showMenuTab(id, $(this));

                if ($('.global-section').hasClass('dentist-management') && (id === 'jsMainNavTab40' || id === 'jsMainNavTab19')) {
                    enableSummernote($('#' + id).find('.summernote-textarea'));
                }

                if ($('.global-section').hasClass('dentist-management')) {
                    changeMetaAndTitle($(this).text(), true);
                }
            }
        });

        /* ON PAGE START CHECK FOR COOKIES RELATIVE TO NAV TAB */
        if (Cookies.get('navTabMemory')) {
            /* CHECKING IF THE PAGE IS REFRESHED IN OTHER WAY REMOVE COOKIES FOR NAV TAB */
            if (Cookies.get('previousUrl') === window.location.href) {
                window.data_id = Cookies.get("navTabMemory");
                var el = $('[data-id="' + Cookies.get("navTabMemory") + '"]');
                showMenuTab(el.attr('data-id'), el);
                changeMetaAndTitle(el.text());
            } else if (!localStorage.getItem('success')) {
                Cookies.remove('navTabMemory', {path: window.location.pathname});
            }
        }

        if (window.location.href.indexOf('coupon=') !== -1 && !$('.global-section').hasClass('dentist-management')) {
            showMenuTab('jsMainNavTab7', $('[data-id="jsMainNavTab7"]'));
        }

        if (window.location.href.indexOf('payment=') !== -1 && $('.global-section').hasClass('dentist-management')) {
            showMenuTab('jsMainNavTab27', $('[data-id="jsMainNavTab27"]'));
        }

        if (localStorage.getItem('addReviewModalRedirect')) {
            localStorage.removeItem('addReviewModalRedirect');
            showMenuTab('jsMainNavTab3', $('[data-id="jsMainNavTab3"]'));
        }

        /* CLICK ON TO TOP BTN, SLIDES PAGE TO TOP */

        /* RESET ALL PAGINATION */
        function resetAllPagination(id) {
            Cookies.set('previousUrl', window.location.href, {expires: 1, path: "/"});
            window.history.pushState('', '', window.location.origin + window.location.pathname);

            if ($('.global-section').hasClass('dentist-management-page')) {
                resetManagePhotoPagination();
                resetManageVideoPagination();
                resetManageCasePagination();
                resetManageArticlePagination();
                resetManageProcedurePagination();
                resetManageCouponPagination();
                resetManageReviewPagination();
                resetManageQaAPagination();
            } else {
                resetLikeKickPagination();
                resetPhotoPagination();
                resetReviewPagination();
                resetCasePagination();
            }

            resetTitleAndMeta();
            resetH1Title();

            function resetTitleAndMeta() {
                var title = $('#defaultTitleId').text();
                $('title').text(title);
                $('meta[name=description]').attr("content", title);
                $('meta[name=author]').attr("content", title);
            }

            function resetH1Title() {
                var h1 = $('#h1MetaTag').text();
                var reviewExp = /\s\(Review (\d*)\)/g;
                var photoExp = /\s\(Photo (\d*)\)/g;
                var caseExp = /\s\(Case (\d*)\)/g;
                var likeExp = /\s\(Like (\d*)\)/g;

                if (h1.search(reviewExp) != -1) {
                    h1 = h1.replace(reviewExp, '').trim();
                    $('#h1MetaTag').text(h1);
                }
                if (h1.search(photoExp) != -1) {
                    h1 = h1.replace(photoExp, '').trim();
                    $('#h1MetaTag').text(h1);
                }
                if (h1.search(caseExp) != -1) {
                    h1 = h1.replace(caseExp, '').trim();
                    $('#h1MetaTag').text(h1);
                }
                if (h1.search(likeExp) != -1) {
                    h1 = h1.replace(likeExp, '').trim();
                    $('#h1MetaTag').text(h1);
                }
            }
        }

        /* REMOVE COUPON FORM BOOKING FORM */
        $('.jsRemoveCoupon').click(function(){
            $('#bookingCoupon').toggle('hidden');
            $('input[name="booking_coupon"]').val('');
            window.history.pushState('','',window.location.origin + window.location.pathname);
        });

        /* TOGGLE SPECIALITIES ON PRACTICE PAGE */
        $('.jsToggleSpecialities').click(function () {
            var title = $(this).html();
            var content = $(this).next().html();
            $('#jsCouponsArticlesModal .jsItemTitle').html(title);
            $('#jsCouponsArticlesModal .jsItemContent').html(content);
            BindShareButton();
            $('#jsCouponsArticlesModal').modal();
        });

        $('.jsToggleNews').click(function () {
            var title = $(this).html();
            var content = $(this).next().html();
            console.log(title, content, $('#jsNewsModal').length);
            // $('#jsNewsModal .jsItemTitle').html(title);
            $('#jsNewsModal .jsItemContent').html(content);
            BindShareButton();
            $('#jsNewsModal').modal();
        });


        $('.jsToggleCoupons').click(function () {
            var id = $(this).attr('data-id');
            $('#jsCouponInfoModal-' + id).modal();
        });

        $('.jsTagAlert button').click(function () {
            $('.jsTagAlert').hide();
        });

        /* ADD DENTAL PHOTOS FUNCTIONAL: */
        ratingDentalSmileys = 0;

        $('#jsDentalPhotoSmileys').on('mouseleave', function() {
            if (ratingDentalSmileys>0) {
                var current = $('#jsDentalPhotoSmileys .smile').eq(ratingDentalSmileys-1);
                smileHoverHandler(current);
            } else {
                $('#jsDentalPhotoSmileys .smile').removeClass('on');
                $('#jsDentalPhotoSmileys .rating-caption').addClass('hidden');
            }
        });

        $('#jsDentalPhotoSmileys .smile').on('mouseenter', function() {
            smileHoverHandler($(this));
        });

        /* SMILE RATING MOUSE CLICK UI */
        $('#jsDentalPhotoSmileys .smile').on('click', function() {
            ratingDentalSmileys = parseInt($(this).attr('data-index'));
        });

        if(!!('ontouchstart' in window)) {
            $('#jsDentalPhotoSmileys .smile').off('click mouseleave mouseenter');
            $('#jsDentalPhotoSmileys .smile').on('mouseenter', function() {
                smileHoverHandler($(this));
                ratingDentalSmileys = parseInt($(this).attr('data-index'));
            });
        }

        /* PREVIEW PHOTO BY URL */ 
        function readURL(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                
                reader.onload = function (e) {
                    $('#jsDentalPhotoUpload').attr('src', e.target.result);
                    $('.jsDentalPhotoWrap').removeClass('hidden');
                }
                
                reader.readAsDataURL(input.files[0]);
            }
        }
        $("#jsDentalPhotoInput").change(function(){
            readURL(this);
        });


    }

    var index = location.href.indexOf('jsMainNavTab7');
    if (index !== -1) {
        changeTabTitleAndMeta(appointmentTitle);
    }
});
