$(function () {
    if ( $('.global-section').hasClass('dentist-registration') ) {

    function validateOfficeHours(e){
        $(".text-danger").hide();
        var timepickers = $(".timeClock");
        var validTime = true;
        for (var index=0; index < timepickers.length; index++) {
            var start = $(timepickers[index]).find('.start');
            var end = $(timepickers[index]).find('.end');
            var errors = $(timepickers[index]).find('.text-danger');
            if (!start.val() && end.val()) {
                $(errors[0]).show();
                validTime = false;
            } else if (start.val() && !end.val()) {
                $(errors[1]).show();
                validTime = false;
            }
            var date1 = Date.parse('01/01/2001 ' + start.val());
            var date2 = Date.parse('01/01/2001 ' + end.val());
            if (start.val() && !date1) {
                $(errors[0]).show();
                validTime = false;
            } else if (end.val() && !date2) {
                $(errors[1]).show();
                validTime = false;
            } else if (date1 > date2) {
                $(errors[2]).show();
                validTime = false;
            }
            if (!validTime) {
                e.preventDefault();
                return false;
            }
        }
    }

    $('.jsOnEnterSbmtInput').keypress(function(e) {
        if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
            $(this).parents('.item-block').find('.jsOnEnterSbmtBtn').click();
            return false;
        } else {
            return true;
        }
    });

    $('#registrationOfficeHours').click(validateOfficeHours);
    $('#browserName').val(Object.keys($.browser)[0]);
    $('#browserVersion').val($.browser.version);
    $('#registration-description').trigger('autoresize');

    }
});
