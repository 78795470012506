$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/procedures';
        var pattern = /procedure/;
        var key = 'procedure';
        var wrapperClass = 'jsManageProcedureWrapper';
        var lastIndexClass = 'jsLastManageProcedureIndex';
        var buttonClass = 'jsShowMoreLessManageProcedureBtn';

        function jsManageProcedurePagination() {
            var btn = $(this);
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManageProcedurePagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-management-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetManageProcedurePagination() {
    resetManagePagination(/procedure/, 'api/procedures', 'procedure', 'jsLastManageProcedureIndex', 'jsManageProcedureWrapper', 'jsShowMoreLessManageProcedureBtn');
}
