$(document).ready(function () {
    if ( $('.global-section').hasClass('faq-page') ) {
        $(document).on('click','.faq-trigger', function() {
            window.location.hash = $(this).prev().attr('id');
            $(this).parent().toggleClass('content-visible').find('.faq-content').slideToggle(200);
        })

        $('.jsCategoryTab').click(function () {
            scrollTo($(this).attr('data-id'));
        })

        function scrollTo(el) {
            $('html, body').animate({scrollTop: $("#" + el).offset().top - 105}, 500);
        }
    }
});