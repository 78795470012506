var PREVIEW_PAGE_COUNTER = 5;

function jsInfoPagination(pattern, apiUrl, key, lastIndexClass, wrapperClass, buttonClass, btn) {

    var searchObj = parseSearchQuery(pattern);
    var isMatched = searchObj.isMatched;
    if (key == 'rating') {
        var url = apiUrl + searchObj.searchVal;
    } else {
        var url = apiUrl + location.pathname + searchObj.searchVal;
    }
    var counterLocal = (isMatched) ? searchObj.matchedElement.match(/\d+$/)[0] : 1;
    var oldPage = (isMatched) ? searchObj.matchedElement.match(/\d+$/)[0] : 1;
    if (btn.attr('data-type') == 'more') {
        if (counterLocal == $('.' + lastIndexClass).text().trim().split(' ')[3]) {
            $(window).on('scroll', checkScrollToBtmReview);
            return false;
        }
        counterLocal++;
        if (isMatched) {
            var searchIndex = url.match(/\?/)['index'];
            url = url.split('');
            url.splice(searchObj.startIndex + searchIndex, searchObj.endIndex, counterLocal);
            url = url.join('');
        } else {
            if (searchObj.searchVal) {

                url = (url + '&' + key + '=' + counterLocal);
            } else {
                url = (url + '?' + key + '=' + counterLocal);
            }
        }
    } else {
        if (counterLocal < 2) {
            $(window).on('scroll', checkScrollToBtmReview);
            return false;
        }
        counterLocal--;
        if (counterLocal == 1) {
            var searchIndex = url.match(searchObj.matchedElement)['index'];
            url = removeMatchFromUrl(url, searchObj);
        } else {
            var searchIndex = url.match(/\?/)['index'];
            url = url.split('');
            url.splice(searchObj.startIndex + searchIndex, searchObj.endIndex, counterLocal);
            url = url.join('');
        }

        var searchParams = parseSearchQuery(/from/);

        if (wrapperClass == 'jsAddReviewRatingWrapper' && counterLocal == PREVIEW_PAGE_COUNTER && searchParams.isMatched) {
            var from = searchParams.matchedElement.match(/\d+$/)[0];
            if (searchParams.isLast) url = url.replace('&'+searchParams.matchedElement, '');
            if (!(searchParams.isLast)) {
                url = url.replace(searchParams.matchedElement+'&', '');
            } 
            url = url.slice(0, -1) + from;
        }
    }
    if (lastIndexClass == 'jsLastPagIndex' && !$('.' + lastIndexClass).text().trim().split(' ')[3]) return;
    $.ajax({
        method: "GET",
        url: location.origin + url,
        beforeSend: function() {
            btn.attr('disabled','disabled');
            $('.reviewPreloader').removeClass('hidden');
        },
        success: function (data) {
            if (pattern == '/review/') {
                $(data).insertAfter($('.' + wrapperClass).last());
            } else {
                $('.' + wrapperClass).replaceWith(data);
            }

            Cookies.set('previousUrl', window.location.href, { expires: 1, path: "/" });
            window.history.pushState({counter: counterLocal}, '', window.location.origin + url.replace(apiUrl,''));
            var offsetW = ( $(window).width() < 768 ) ? 26 : 86;
            
            if (pattern == '/review/') {
                $(window).on('scroll', checkScrollToBtmReview);
                $('.reviewPreloader').addClass('hidden');
            } else {
                $('body').animate({scrollTop: $('.' + wrapperClass).offset().top - offsetW}, 500);
            }

            btn.removeAttr('disabled','disabled');
            if (key != 'rating') {
                updateInfoMetaTags(counterLocal, oldPage, key);
            } else {
                initAddReview();
            }
            hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
            BindShareButton();
        }
    });
}

function updateInfoMetaTags(currentPage, oldPage, key) {
    var contentTitle;
    var additional_text;

    if (key === 'case') {
        contentTitle = $('#mediaContentTitle').val();
    }

    var titleKey = key.toTitleCase();
    var descriptionContent = $('meta[name=description]').attr("content");
    var title = $('title').text();
    var h1 = $('#h1MetaTag').text();

    if (oldPage == 1 && currentPage > oldPage) {

        descriptionContent = descriptionContent.split(' (')[0];
        title = title.split(' (')[0];
        h1 = h1.split(' (')[0];

        if (contentTitle) {
            additional_text = ' (' + contentTitle + ')';
        } else {
            additional_text = ' (' + titleKey + ' 2)';
        }

        $('meta[name=description]').attr("content", descriptionContent + additional_text);
        $('meta[name=author]').attr("content", descriptionContent + additional_text);
        $('title').text(title + additional_text);
        $('#h1MetaTag').text(h1 + additional_text);

    } else if (oldPage != 1 &&  currentPage != 1) {

        if (contentTitle) {
            additional_text = ' (' + contentTitle + ')';
        } else {
            additional_text = ' (' + titleKey + ' ' + currentPage + ')';
        }

        descriptionContent = descriptionContent.split(' (')[0] + additional_text;
        title = title.split(' (')[0] + additional_text;
        h1 = h1.split(' (')[0] + additional_text;
        $('meta[name=description]').attr("content", descriptionContent);
        $('meta[name=author]').attr("content", descriptionContent);
        $('title').text(title);
        $('#h1MetaTag').text(h1);
    } else {
        descriptionContent = descriptionContent.split(' - (')[0];
        title = title.split(' (')[0];
        h1 = h1.split(' (')[0];
        $('meta[name=description]').attr("content", descriptionContent);
        $('meta[name=author]').attr("content", descriptionContent);
        $('title').text(title);
        $('#h1MetaTag').text(h1);
    }
}


function resetPagination(pattern, apiUrl, key, lastIndexClass, wrapperClass,  buttonClass) {
    $.ajax({
        method: "GET",
        url: apiUrl + window.location.pathname + '?'+ key +'=1',
        success: function (data) {
            if (wrapperClass == 'jsReviewWrapper') {
                $(data).insertBefore($('.'  + wrapperClass).first()); 
                $('.'  + wrapperClass).not(":eq(0)").remove();
            }
            $('.'  + wrapperClass).replaceWith(data);
            updateManageMetaTags(1, '2', key);
            hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
            if (key === 'rating') {
                initAddReview();
                initAddReviewPagination();
            }
        }
    });
}
