$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/questions';
        var pattern = /question/;
        var key = 'question';
        var wrapperClass = 'jsManageQaAWrapper';
        var lastIndexClass = 'jsLastManageQaAIndex';
        var buttonClass = 'jsShowMoreLessManageQaABtn';

        function jsManageQaAPagination() {
            var btn = $(this);
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManageQaAPagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-management-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetManageQaAPagination() {
    resetManagePagination(/question/, 'api/questions', 'question', 'jsLastManageQaAIndex', 'jsManageQaAWrapper', 'jsShowMoreLessManageQaABtn');
}
