if (window.user_is_authenticated) {
    var LAST_REVIEW_NO_USER_STEP = '3';
} else {
    var LAST_REVIEW_NO_USER_STEP = '3';
}

var MIN_ALLOWED_REVIEW_LENGTH = 0;
var addReviewRatingScore = [0,0,0,0,0,0];

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

function sendRatingValue(e) {
    var searchParams = new URLSearchParams(location.search);
    var from_step = searchParams.get('from_step');
    var toRedirect;
    var review = '';
    var btn = $(this);
    var button_option = e.data.option;
    var current_step = e.data.current_step;
    var btnId = btn.get(0) ? btn.get(0).id : 'addReviewRatingBtnNext';
    var smiles = generateValidJson();
    if (!smiles) return;
    var data = {
        'csrfmiddlewaretoken': window.csrf_token,
        'step': getUrlParameter('rating') ? getUrlParameter('rating') : 1,
        'value': JSON.stringify(smiles),
        'index': $('.jsLastAddReviewRatingIndex').text().trim().split(' ')[3],
    };

    data = handleReviewForm(data);
    if ($('input[name=anonymous]').length) {
        data['anonymous'] = $('input[name=anonymous]:checked').val();
    }
    data['review_id'] = getUrlParameter('review_id');
    resetReviewRatingScore();
    if (localStorage.getItem('reviewStep')) localStorage.removeItem('reviewStep');

    $.ajax({
        method: "POST",
        url: '/reviews/post_review/' + $('#dentist-slug').val(),
        dataType: "json",
        data: data,
        beforeSend: function () {
            btn.prop('disabled', true);
            loader.show();
        },
        success: function (response) {
            if (response.temp_review_id) {
                review =  "&review_id=" +response.temp_review_id
            }
            if (button_option == 'firstStep') {
                return
            } else if (response.step === LAST_REVIEW_NO_USER_STEP && 'addReviewRatingBtnNext' === btnId) {
                submitCompletedForm(data, button_option, review, btn);
            } else if (button_option === 'clickToEnd') {
                location.href = location.pathname + '?rating=' + PREVIEW_PAGE_NUM + '&from_step=' + current_step + review + '#add_review'
            } else {
                if (from_step && button_option === 'prev') {
                    location.href = location.pathname + '?rating=' + from_step + review + '#add_review'
                } else {
                    toRedirect = button_option === 'next' ? response.next_step : response.prev_step;
                    var toRedirectHref = location.pathname + '?rating=' + toRedirect + review;
                    localStorage.setItem('reviewStep', toRedirectHref);
                    location.href = toRedirectHref + '#add_review'
                }
            }
        }
    });
}

function submitCompletedForm(data, button_option, review, btn) {
    data['review_id'] = getUrlParameter('review_id');
    $.ajax({
        type: "POST",
        url: '/reviews/api/add-review-summary/' + $('#dentist-slug').val() + '/',
        data: data,
        beforeSend: function () {
            btn.prop('disabled', true);
        },
        success: function (response) {
            if (response.is_posted) {
                location.href = '/'+$('#dentist-slug').val() + '#jsMainNavTab1';
            } else {
                var toRedirect = button_option === 'next' ? response.next_step : response.prev_step;
                location.href = location.pathname + '?rating=' + toRedirect + review + '#add_review'
            }
        },
        error: function (error) {}
    });
}

function reviewCharactersCounter() {
    var textarea = $(this);
    var maxlength = textarea.prop('maxlength');
    var error = $('#jsAddReviewCommentError');
    var remainingCharacters = $('#addReviewCommentCount');
    if (textarea && textarea.val() && textarea.val().length) {
        if (textarea.val().length > maxlength) {
            textarea.val(textarea.val().slice(0, maxlength-textarea.val().length))
        }
        var smiles = generateValidJson();
        var active = true;
        addReviewRatingScore.forEach(function(el,index) {
            if (el == 0) active = false;
        });
        var characters_left = maxlength - textarea.val().length;
        if (textarea.val().length < MIN_ALLOWED_REVIEW_LENGTH) {
            error.parent().removeClass('hidden');
            error.text('Please enter your review.');
            $('#addReviewRatingBtnNext').prop('disabled', true);
        } else if (active && !smiles.validationError) {
            error.parent().addClass('hidden');
            error.text('');
            $('#addReviewRatingBtnNext').prop('disabled', false);
        }
        remainingCharacters.text(characters_left);
    } else {
        remainingCharacters.text(maxlength);
        error.parent().removeClass('hidden');
        error.text('Please enter your review.');
        $('#addReviewRatingBtnNext').prop('disabled', true);
    }
}

function handleReviewForm(data) {
    if ($('.review-form').length) {
        var form = $('.review-form');
        $.each(form.serializeArray(), function (index, el) {
            data[el.name] = el.value.trim();
        });
    }
    return data
}

function generateValidJson() {
    var allRatings = $('.addReviewBlock');
    var blocks = allRatings.find('.smile-rating-module');
    var allMin = 0;
    var allMax = 0;
    var data = {};
    $.each(blocks, function (index, el) {
        var smileIndex = $(el).find('.relative .on').last().attr('data-index');
        var rating_key = $(el).find('.rating-key').val();
        data[$(el).find('.rating-key').val()] = smileIndex;
        if (smileIndex == '1' && rating_key != 'question_6' && rating_key != 'question_7' && rating_key !=  'hygiene_rating' && rating_key != 'front_desk_rating') {
            allMin ++;
        } else if (smileIndex == '4' && rating_key != 'question_6' && rating_key != 'question_7' && rating_key !=  'hygiene_rating' && rating_key != 'front_desk_rating') {
            allMax ++;
        }
    });
    if (allMax >= 6) {
        data['validationError'] = "This rank is too good to be true! </br> Like 5-star hotel and cheap!";
    } else if (allMin >= 6) {
        data['validationError'] = "This rank is too bad to be true! </br> Extreme cost and poor facilities.";
    }
    return data
}

function resetReviewRatingScore() {
    addReviewRatingScore = [0,0,0,0,0,0];
    $('.jsIndividReviewRatingSmiles').each(function(index, el) {
        var smileNum = parseInt($(el).find('.on').last().attr('data-index') );
        if (smileNum) {
            addReviewRatingScore[index] = smileNum;
        }
    });

    if (!$('#reviewFormStep').length) {
        addReviewRatingScore.forEach(function(el,index) {
            if (el == 0) $('#addReviewRatingBtnNext').prop('disabled', true);
        });
    }
}

function initAddReview() {
    $('#clickToEndReview').click({'option': 'clickToEnd'}, clickToEnd);
    /* VISUAL PART FOR HOVER AND CLICKS ON SMILEYS */
    $('.jsIndividReviewRatingSmiles .smile').on('mouseenter', function() {
        smileHoverHandler($(this),'right_caption');
    });

    $('.jsIndividReviewRatingSmiles').on('mouseleave', function() {
            var thisGroupIndex = $(this).parent().attr('data-groupindex');
            if (addReviewRatingScore[thisGroupIndex] > 0) {
                var current = $(this).find('.smile').eq(addReviewRatingScore[thisGroupIndex]-1);
                smileHoverHandler(current,'right_caption');
            } else {
                $(this).find('.smile').removeClass('on');
                $(this).parent().find('.rating-caption').addClass('hidden');
            }
        }
    );

    $('.jsIndividReviewRatingSmiles .smile').on('click', function(e) {
        reviewRatingSmileClickHandled($(this));
        var active = true;
        addReviewRatingScore.forEach(function(el,index) {
            if (el == 0) active = false;
        });

        var smiles = generateValidJson();
        var error = $('#jsAddReviewValidationError');
        var btnNext = $('#addReviewRatingBtnNext');

        //console.log(smiles.validationError);

        if (smiles.validationError) {
           error.parent().removeClass('hidden');
           error.html(smiles.validationError);
           btnNext.prop('disabled', true);
        } else if (!smiles.validationError) {
           error.parent().addClass('hidden');
           error.html('');
           if ( active && $('#addReviewComment').val().length ) {
             btnNext.prop('disabled', false);
           }
        }
    });

    /* HOTFIX FOR IOS/ANDROID */
    if(!!('ontouchstart' in window)) {
        $('.jsIndividReviewRatingSmiles .smile').off('click mouseleave mouseenter');
        $('.jsIndividReviewRatingSmiles .smile').on('mouseenter', function() {
            smileHoverHandler($(this),'right_caption');
            reviewRatingSmileClickHandled($(this));
            var active = true;
            addReviewRatingScore.forEach(function(el,index) {
                if (el == 0) active = false;
            });
            var smiles = generateValidJson();
            var error = $('#jsAddReviewValidationError');
            var btnNext = $('#addReviewRatingBtnNext');

            if (smiles.validationError) {
               error.parent().removeClass('hidden');
               error.html(smiles.validationError);
               btnNext.prop('disabled', true);
            } else if (!smiles.validationError && active) {
               error.parent().addClass('hidden');
               error.html('');
               btnNext.prop('disabled', false);
            }
        });
    }

    function reviewRatingSmileClickHandled(el) {
        addReviewRatingScore[el.parents('.smile-rating-module').attr('data-groupindex')] = parseInt(el.attr('data-index'));
    }

    function showOptionalRating(e) {
        var index = $(this).attr('data-ratingindex');
        var baseDiv = $(this).parents('.jsAddReviewRatingWrapper');
        var smilesDiv =  baseDiv.find('[data-groupindex="'+ index +'"]');
        if ($(this).val() == '1') {
            smilesDiv.removeClass('hidden');
        } else {
            smilesDiv.addClass('hidden');
            handelOptionalSmiles(smilesDiv, '.smile', 'remove', 'on');
            handelOptionalSmiles(smilesDiv, '.rating-caption', 'add', 'hidden');
        }
    }

    function handelOptionalSmiles(smilesDiv, element, action, class_name) {
        $.each(smilesDiv.find(element), function (index, el) {
            if (action == 'remove') {
                $(el).removeClass(class_name);
            } else if (action == 'add'){
                $(el).addClass(class_name);
            }
        });
    }
    if ($('.show-optional-rating').length) {
        $('.show-optional-rating').click(showOptionalRating);
    }

    function saveLocation() {
        localStorage.setItem('reviewStep', location.href);
    }

    $('#addReviewRatingBtnNext').unbind('click').click({'option': 'next'}, sendRatingValue);
    $('#addReviewRatingBtnPrev').unbind('click').click({'option': 'prev'}, sendRatingValue);
    $('#post-review-sign-up').click(saveLocation);
    resetReviewRatingScore();

    if ($('#reviewFormStep').length && $('#addReviewComment').length) {
        var textarea = $('#addReviewComment');

        var active = true;
        addReviewRatingScore.forEach(function(el,index) {
            if (el == 0) active = false;
        });
        if (!active || !$('#addReviewComment').val().length) $('#addReviewRatingBtnNext').prop('disabled', true);

        textarea.on('input', reviewCharactersCounter);
    }

    if (location.pathname.indexOf('/reviews/post_review/') >= 0 && !getUrlParameter('rating')) {
        localStorage.setItem('reviewStep', location.pathname);
    }
}

initAddReview();



