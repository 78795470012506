$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/coupons';
        var pattern = /coupon/;
        var key = 'coupon';
        var wrapperClass = 'jsManageCouponWrapper';
        var lastIndexClass = 'jsLastManageCouponIndex';
        var buttonClass = 'jsShowMoreLessManageCouponBtn';

        function jsManageCouponPagination() {
            var btn = $(this);
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManageCouponPagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-management-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetManageCouponPagination() {
    resetManagePagination(/coupon/, 'api/coupons', 'coupon', 'jsLastManageCouponIndex', 'jsManageCouponWrapper', 'jsShowMoreLessManageCouponBtn');
}
