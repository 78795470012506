$(document).ready(function(){

	/* MODULE FOR ONLY LOCATIONS PAGES EG: MAIN/METRO/CITY/ETC */

	if ( $('.global-section').hasClass('dentist-location') ) {
		$(document).on("click", ".jsMainNavItem", function () {
			var id = $(this).attr("data-id");
			showMenuTab(id, $(this));
		});


		$(document).on("change", '.jsNativeLocDropdown', function(){
		    if ( $(this).val() != 0 ) {
		      console.log($(this).val());
		      window.location.href = '/?' + $(this).attr('data-name') + '=' + $(this).val();
		    } else {
		      window.location.href = '/';
		    }
	  	});
	}
});