/* FUNCTION FOR PAGINATION ARTICLES / CASES / VIDEOS / COUPONS*/
function jsNoAjaxPagination(query,maxElementsCount,queryType,itemWrapper,el,btnClass) {
    var btn = el;
    var searchObj = parseSearchQuery(query);
    var isMatched = searchObj.isMatched;
    var url = '/api' + window.location.pathname + searchObj.searchVal;
    var counterLocal = (isMatched) ? searchObj.matchedElement.match(/\d+$/)[0] : 1;

    if (btn.attr('data-type') == 'more') {
        if (counterLocal == maxElementsCount) {
            return false;
        }
        counterLocal++;
        if (isMatched) {
            var searchIndex = url.match(/\?/)['index'];
            url = url.split('');
            url.splice(searchObj.startIndex+searchIndex,searchObj.endIndex, counterLocal);
            url = url.join('');
        } else {
            if (searchObj.searchVal) {
                url = (url + '&' + queryType + '=' + counterLocal);
            } else {
                url = (url + '?' + queryType + '=' + counterLocal);
            }
        }
        /* LOGIC FOR CASES TOGGLING */
        $('.'+itemWrapper).not('.hidden').addClass('hidden').next().removeClass('hidden');
    } else {
        if (counterLocal < 2) {
            return false;
        }
        counterLocal--;
        if (counterLocal == 1) {
            var searchIndex = url.match(searchObj.matchedElement)['index'];
            url = removeMatchFromUrl(url,searchObj);
        } else {
            var searchIndex = url.match(/\?/)['index'];
            url = url.split('');
            url.splice(searchObj.startIndex+searchIndex,searchObj.endIndex, counterLocal);
            url = url.join('');
        }
        $('.'+itemWrapper).not('.hidden').addClass('hidden').prev().removeClass('hidden');
    }
    Cookies.set('previousUrl', window.location.href, { expires: 1, path: "/" });
    window.history.pushState({numCounter: counterLocal, type: queryType}, '', window.location.origin + url.replace('/api',''));
    hideShowButtons( query, btnClass, maxElementsCount );
    $('body').animate({scrollTop: $("." + itemWrapper).not('.hidden').offset().top - 80}, 500);
}