/* SEND SEARCH QUERY TO SERVER AND HANDLE RESPONSE UI */

function searchRequest() {
    var searchResult = $(this).parents('.elastic-search').find('.search-result');
    $.ajax({
            method: "GET",
            url: "/search/",
            dataType: "json",
            data: {
                "q": $(this).val(),
                "async": true
            },
            success: function (result) {
                searchResult.empty().removeClass("hidden");
                if (result.length) {
                    for (var index in result) {
                          var url = '/' + result[index]._source.slug;
                          var city_text_zip = result[index]._source.city != null  ? result[index]._source.city + ', ' : '';
                          var state_text_zip = result[index]._source.state_text_zip != null  ? result[index]._source.state_text_zip : '';
                          var city_state = '<br> ('+ city_text_zip + state_text_zip + ')';
                          searchResult.append(
                            '<li>' +
                            '<a href=' + url +'>'+
                            ''+result[index]._source.first_name +' '+ result[index]._source.last_name +
                            city_state +
                            '</a>'+
                            '</li>'
                        )
                    }
                } else {
                    searchResult.append(
                        '<li class="px-1">No results</li>'
                    )
                }

                function hideDropdown(e) {
                    if ( !($(e.target).closest(".elastic-search").length) ) {
                        searchResult.addClass("hidden");
                    }
                }
                $(document).on("click",hideDropdown);
                $(document).on("focus",".search-input",function () {
                    searchResult.removeClass("hidden");
                });
            }
        }
    );
}

function searchRegistration() {
    $.ajax({
            method: "GET",
            url: "/search/",
            dataType: "json",
            data: {
                "q": $(this).val(),
                "async": true
            },
            success: function (result) {
                var searchResult = $('#search-res');
                searchResult.empty().removeClass("hidden");
                if (result.length) {
                    for (var index in result) {
                        var url = "";
                        if (result[index]._source.is_registered) {
                            url = "/dentist/registration/notification/?dentist_id=" + result[index]._source.dentist_id;
                        }
                        else {
                            url = "/dentist/registration/1/?dentist_id="+result[index]._source.dentist_id;
                        }
                        var city_text_zip = result[index]._source.city != null  ? result[index]._source.city + ', ' : '';
                        var state_text_zip = result[index]._source.state_text_zip != null  ? result[index]._source.state_text_zip : '';
                        var city_state = '<br> ('+ city_text_zip + state_text_zip + ')';
                        searchResult.append(
                            '<li>' +
                            '<a href=' + url +'>'+
                            ''+result[index]._source.first_name +' '+ result[index]._source.last_name +
                            city_state +
                            '</a>'+
                            '</li>'
                        )
                    }
                }

                if (searchResult.children().length == 0) {
                    searchResult.append(
                        '<li class="px-1">No results</li>'
                    )
                }

                function hideDropdown(e) {
                    if ( !($(e.target).closest(".elastic-search").length) ) {
                        searchResult.addClass("hidden");
                    }
                }
                $(document).on("click",hideDropdown);
                $(document).on("focus","#search-register-input",function () {
                    searchResult.removeClass("hidden");
                });
            }
        }
    );
}

function searchToAddDentistRequest() {
     var searchResult = $(this).parents('.elastic-search').find('.search-result');
     var currentUrl = location.pathname;
     var url_parts = currentUrl.split('/');

     var new_url = location.origin + '/comparison-key/';
     console.log(new_url);
     var vs_index = url_parts.indexOf('vs');
     if (vs_index != -1 && vs_index != 0) {
         new_url += url_parts[vs_index - 1] + '/vs/'
     } else {
         var compare_index = url_parts.indexOf('comparison-key');
         if (compare_index != -1 && compare_index != 0) {
             new_url += url_parts[compare_index + 1] + '/vs/'
         }
     }
    $.ajax({
            method: "GET",
            url: location.origin + "/search/",
            dataType: "json",
            data: {
                "q": $(this).val(),
                "async": true
            },
            success: function (result) {
                searchResult.empty().removeClass("hidden");
                if (result.length) {
                    for (var index in result) {
                          var url = new_url;
                          url += result[index]._source.slug + '/#add_comparison';

                          console.log(new_url);
                          var city_text_zip = result[index]._source.city != null  ? result[index]._source.city + ', ' : '';
                          var state_text_zip = result[index]._source.state_text_zip != null  ? result[index]._source.state_text_zip : '';
                          var city_state = '<br> ('+ city_text_zip + state_text_zip + ')';
                          searchResult.append(
                            '<li>' +
                            '<a href=' + '"' + url + '"' +'>'+
                            ''+result[index]._source.first_name +' '+ result[index]._source.last_name +
                            city_state +
                            '</a>'+
                            '</li>'
                        )
                    }
                } else {
                    searchResult.append(
                        '<li class="px-1">No results</li>'
                    )
                }

                function hideDropdown(e) {
                    if ( !($(e.target).closest(".elastic-search").length) ) {
                        searchResult.addClass("hidden");
                    }
                }
                $(document).on("click",hideDropdown);
                $(document).on("focus",".search-input",function () {
                    searchResult.removeClass("hidden");
                });
            }
        }
    );

}

$(function () {
    $('.search-dentist-input').keyup(searchToAddDentistRequest);
    $('#search-register-input').keyup(searchRegistration);
    $('.search-input').keyup(searchRequest);
});