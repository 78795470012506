$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-review-page') ) {
        var apiUrl = '/api/cases';
        var pattern = /case/;
        var key = 'case';
        var wrapperClass = 'jsCaseWrapper';
        var lastIndexClass = 'jsLastCaseIndex';
        var buttonClass = 'jsShowMoreLessCaseBtn';
        function jsCasePagination() {
            var btn = $(this);
            jsInfoPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }
        hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsCasePagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-review-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetCasePagination() {
    resetPagination(/case/, 'api/cases', 'case', 'jsLastCaseIndex', 'jsCaseWrapper', 'jsShowMoreLessCaseBtn');
}

