$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-review-page') ) {
        var apiUrl = '/api';
        var pattern = /review/;
        var key = 'review';
        var wrapperClass = 'jsReviewWrapper';
        var lastIndexClass = 'jsLastPagIndex';
        var buttonClass = 'jsShowMoreLessReviewBtn';

        function jsReviewPagination() {
            var btn = $(this);
            jsInfoPagination(pattern, apiUrl, key, lastIndexClass, wrapperClass,buttonClass, btn);
        }
        hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsReviewPagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-review-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetReviewPagination() {
    resetPagination(/review/, '/api', 'review', 'jsLastPagIndex', 'jsReviewWrapper', 'jsShowMoreLessReviewBtn');
}

/* FUNCTION FOR INFINITE SCROLL CHECKING */

function checkScrollToBtmReview() {   

    /* SET GOOGLE ANALYTICS EVENT */
    if ( $('.global-section').hasClass('dentist-review-page') && !window._gaFirstBreak50 && $(window).scrollTop() > $(document).height() * 0.3 ) {
        window._gaFirstBreak50 = true;
        gtag('event', 'page_view', {
            'send_to': 'UA-31019763-1',
            'block_id': '30%_page_scrolled'
        });
    }

    /* SET GOOGLE ANALYTICS EVENT */
    if ( $('.global-section').hasClass('dentist-review-page') && !window._gaFirstBreak50 && $(window).scrollTop() > $(document).height() * 0.5 ) {
        window._gaFirstBreak50 = true;
        gtag('event', 'page_view', {
            'send_to': 'UA-31019763-1',
            'block_id': '50%_page_scrolled'
        });
    }

    /* SET GOOGLE ANALYTICS EVENT */
    if ( $('.global-section').hasClass('dentist-review-page') && !window._gaFirstBreak90 && $(window).scrollTop() > $(document).height() * 0.9 ) {
        window._gaFirstBreak90 = true;
        gtag('event', 'page_view', {
            'send_to': 'UA-31019763-1',
            'block_id': '90%_page_scrolled'
        });
    }

    /* SCROLL TO BOTTOM REVIEW FUNCTION */
    var offsetW = 0;
    if ( $(window).width() < 768 ) {
        offsetW = 100;
    }
    if( (Math.floor( $(window).scrollTop() ) + $(window).height()) >= ($(document).height() - offsetW) ) {
       $(window).off('scroll', checkScrollToBtmReview);
       if (!window.no_reviews) {
           launchMoreReviews();
       } else {
            window.history.pushState('', '', window.location.origin + window.location.pathname);
            Cookies.set('previousUrl', window.location.origin + window.location.pathname, {expires: 1, path: "/"});
       }
   }
}

function launchMoreReviews() {
    var apiUrl = '/api';
    var pattern = /review/;
    var key = 'review';
    var wrapperClass = 'jsReviewWrapper';
    var lastIndexClass = 'jsLastPagIndex';
    var buttonClass = 'jsShowMoreLessReviewBtn';

    jsInfoPagination(pattern, apiUrl, key, lastIndexClass, wrapperClass, buttonClass, $('.jsShowMoreLessReviewBtn[data-type="more"]'));
}
