
function sendReplyMessage(e) {
    var form = $('#sent-reply-form');
    var formData = new FormData(form[0]);
    formData.append('send_reply', true);
    e.preventDefault();
    loader.show();
    $.ajax({
        type: "POST",
        url: window.location.href,
        dataType : "json",
        data: formData,
        processData: false,
        contentType: false,
        success: function (result) {
            if (result.success) {
                toastr.success('Your message was successfully sent!');
                setTimeout(function () { location.reload() }, 1000);
            } else if (!result.success && result.message_error) {
                toastr.error('Message is empty!');
                setTimeout(function () { location.reload() }, 1000);
            } else {
                location.reload();
            }
        },
        error: function () {
            loader.hide();
            toastr.error('Unknown error!');
            setTimeout(function(){ window.location.reload() }, 1000);
        }
    });
}

function sendRemoveReviewRequest(e) {
    var form = $('#remove-review-form');
    var formData = new FormData(form[0]);
    e.preventDefault();
    loader.show();
    $.ajax({
        type: 'POST',
        url: window.location.href,
        dataType: 'json',
        data: formData,
        processData: false,
        contentType: false,
        success: function (result) {
            loader.hide();
            if (result.success) {
                toastr.success('Your remove request was successfully sent!');
                setTimeout(function () { location.href = location.origin + '/' + result.slug; }, 1100);
            } else location.reload();
        },
        error: function () {
            loader.hide();
            toastr.error('Unknown error!');
            setTimeout(function(){ window.location.reload() }, 1000);
        }
    });
}

function deleteReplyMessage(message_id, close) {
    var formData = new FormData();
    formData.append('reply_pk', message_id);
    formData.append('remove_reply', true);
    formData.append('csrfmiddlewaretoken', window.csrf_token);

    var dialog_question = close ? 'If you delete this message your request will be canceled. Are you sure you want to delete it and display this review?' : 'Are you sure you want to delete this message?';

    toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteReplyMessageYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteReplyMessageNo'>No</button></div>", dialog_question,
        {
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function() {
                toast = $(this);
                $('#deleteReplyMessageYes').click(function(){
                    deleteReplyRequest(formData);
                });
                $('#deleteReplyMessageNo').click(function(){
                    toastr.clear(toast, { force: true });
                });
            }
        });


}

function deleteReplyRequest(formData) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        dataType: 'json',
        data: formData,
        processData: false,
        contentType: false,
        success: function (result) {
            if (result.success && result.close) {
                toastr.success('Thread is closed. Review is visible');
                setTimeout(function () { location.href = location.origin + '/' + result.slug; }, 1200);
            } else if (result.success) {
                toastr.success('Message successfully deleted!');
                setTimeout(function () { location.reload(); }, 1000);
            } else location.reload();
        },
        error: function () {
            toastr.error('Unknown error!');
            setTimeout(function(){ window.location.reload() }, 1000);
        }
    });
}


$(document).ready(function() {
    $('#sent-reply-button').click(sendReplyMessage);
    if ($('#reply-page-login-button').length) {
        $('#reply-page-login-button').click(loginReplyPage);
    }
    $('#remove-review-button').click(sendRemoveReviewRequest);
});