//TODO: add encapsulation
$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/reviews';
        var pattern = /review/;
        var key = 'review';
        var wrapperClass = 'jsManageReviewWrapper';
        var lastIndexClass = 'jsLastManageReviewIndex';
        var buttonClass = 'jsShowMoreLessManageReviewBtn';

        function jsManageReviewPagination() {
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass, wrapperClass, buttonClass, $(this));
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManageReviewPagination);

        window.onpopstate = function(event) {
            if ((event.state && event.state.counter) || $('.dentist-management-page').length) {
                window.location.reload();
            }
        };
    }
});

function resetManageReviewPagination() {
    resetManagePagination(/review/, 'api/reviews', 'review', 'jsLastManageReviewIndex', 'jsManageReviewWrapper', 'jsShowMoreLessManageReviewBtn');
}
