String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

function jsManagementPagination(pattern, apiUrl, key, lastIndexClass, wrapperClass, buttonClass, btn) {

    var searchObj = parseSearchQuery(pattern);
    var isMatched = searchObj.isMatched;
    var url = location.pathname + apiUrl + searchObj.searchVal;
    var counterLocal = (isMatched) ? searchObj.matchedElement.match(/\d+$/)[0] : 1;
    var oldPage = (isMatched) ? searchObj.matchedElement.match(/\d+$/)[0] : 1;


    if (btn.attr('data-type') == 'more') {
        if (counterLocal == $('.' + lastIndexClass).text().trim().split(' ')[3]) {
            return false;
        }
        counterLocal++;
        if (isMatched) {
            var searchIndex = url.match(/\?/)['index'];
            url = url.split('');
            url.splice(searchObj.startIndex + searchIndex, searchObj.endIndex, counterLocal);
            url = url.join('');
        } else {
            if (searchObj.searchVal) {
                url = (url + '&' + key + '=' + counterLocal);
            } else {
                url = (url + '?' + key + '=' + counterLocal);
            }
        }
    } else {
        if (counterLocal < 2) {
            return false;
        }
        counterLocal--;
        if (counterLocal == 1) {
            var searchIndex = url.match(searchObj.matchedElement)['index'];
            url = removeMatchFromUrl(url, searchObj);
        } else {
            var searchIndex = url.match(/\?/)['index'];
            url = url.split('');
            url.splice(searchObj.startIndex + searchIndex, searchObj.endIndex, counterLocal);
            url = url.join('');
        }
    }

    $.ajax({
        method: "GET",
        url: url,
        beforeSend: function() {
            btn.attr('disabled','disabled');
        },
        success: function (data) {
            $('.' + wrapperClass).replaceWith(data);
            Cookies.set('previousUrl', window.location.href, { expires: 1, path: "/" });
            window.history.pushState({counter: counterLocal}, '', window.location.origin + url.replace(apiUrl,''));
            if (!Cookies.get('scrollTo')) {
                $('body').animate({scrollTop: $('.' + wrapperClass).offset().top - 80}, 500);
            } else {
                var wrapperId = Cookies.get('scrollTo');
                $('html,body').animate({ scrollTop: $('#'+wrapperId).offset().top - 50}, 'slow');
                Cookies.remove('scrollTo');
            }
            btn.removeAttr('disabled','disabled');
            updateManageMetaTags(counterLocal, oldPage, key);
            hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);

            if (wrapperClass == 'jsManageArticleWrapper' || wrapperClass == 'jsManageNewsWrapper') {
                $('.summernote-textarea').each(function(index, elem) {
                    if (elem.id) {
                        $(elem).summernote({
                            minHeight: 150,
                            focus: true,
                            toolbar: [
                                ['style', ['fontname', 'fontsize', 'bold', 'italic', 'underline', 'color']],
                                ['para', ['ul', 'ol', 'paragraph']],
                                ['height', ['height']],
                                ['insert', ['link', 'hr']]
                            ]
                        });
                        // $('#' + elem.id).parent().append( $('#' + elem.id) );
                        $('#' + elem.id).trigger('autoresize');
                    }
                });
            }
            $('.' + wrapperClass).find('.materialize-textarea').trigger('autoresize');
        }
    });
}


function updateManageMetaTags(currentPage, oldPage, key) {
    var titleKey = key.toTitleCase();
    var descriptionContent = $('meta[name=description]').attr("content");
    var title = $('title').text();
    if (oldPage == 1 && currentPage > oldPage) {
        $('meta[name=description]').attr("content", descriptionContent + ' (' + titleKey + ' 2)');
        $('meta[name=author]').attr("content", descriptionContent + ' (' + titleKey + ' 2)');
        $('title').text(title + ' (' + titleKey + ' 2)');
    } else if (oldPage != 1 &&  currentPage != 1) {
        descriptionContent = descriptionContent.replace(titleKey + ' ' + oldPage, titleKey + ' ' + currentPage);
        title = title.replace(titleKey + ' ' + oldPage, titleKey + ' ' + currentPage);
        $('meta[name=description]').attr("content", descriptionContent);
        $('meta[name=author]').attr("content", descriptionContent);
        $('title').text(title);
    } else {
        descriptionContent = descriptionContent.replace(' (' + titleKey + ' ' + oldPage + ')', '');
        title = title.replace(' (' + titleKey + ' ' + oldPage + ')', '');
        $('meta[name=description]').attr("content", descriptionContent);
        $('meta[name=author]').attr("content", descriptionContent);
        $('title').text(title);
    }
}

function resetManagePagination(pattern, apiUrl, key, lastIndexClass, wrapperClass,  buttonClass) {
    $.ajax({
        method: "GET",
        url: window.location.pathname + apiUrl + '?'+ key +'=1',
        success: function (data) {
            $('.'  + wrapperClass).replaceWith(data);
            updateManageMetaTags(1, '2', key);
            hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
        }
    });
}

