/* THROTLED RESIZE PLUGIN */
(function($) {

    var $event = $.event,
        $special,
        dummy = {_:0},
        frame = 0,
        wasResized, animRunning;

    $special = $event.special.throttledresize = {
        setup: function() {
            $( this ).on( "resize", $special.handler );
        },
        teardown: function() {
            $( this ).off( "resize", $special.handler );
        },
        handler: function( event, execAsap ) {
            // Save the context
            var context = this,
                args = arguments;

            wasResized = true;

            if ( !animRunning ) {
                setInterval(function(){
                    frame++;

                    if ( frame > $special.threshold && wasResized || execAsap ) {
                        // set correct event type
                        event.type = "throttledresize";
                        $event.dispatch.apply( context, args );
                        wasResized = false;
                        frame = 0;
                    }
                    if ( frame > 9 ) {
                        $(dummy).stop();
                        animRunning = false;
                        frame = 0;
                    }
                }, 30);
                animRunning = true;
            }
        },
        threshold: 0
    };

})(jQuery);

if ($("#chart_div").length) {
    google.charts.load('current', {'packages': ['corechart']});
    if (window.location.pathname.split('/').indexOf('registration') == -1) {
        google.charts.setOnLoadCallback(drawChart);
    }

    function createLegendNodes(data, colour_list) {
        var color_squares = document.getElementById('double');
        color_squares.innerHTML = '';
        for (var i = 0; i < data.length; i++) {
            color_squares.innerHTML += "<li onclick=sliceToggler(" + i + ")><span></span>" + data[i][0] + "</li>";
            color_squares.children[i].firstChild.style.backgroundColor = colour_list[i];
        }
    }

    function sliceToggler(item_id) {
        if (item.is_already_selected(item_id)) {
            chart.setSelection(null);
            item.item_id = null;
        } else {
            chart.setSelection([{row: item_id}]);
            item.item_id = item_id;
        }
    }

    function drawChart(rows, options) {
        //if ($('#chart_div').html()) return;
        window._chartCounter++;

        if (!window.chart_rows) {
            $.ajax({
                url: '/api/procedure_breakdown' + window.location.pathname,
                type: 'GET',
                datType: 'json',
                success: function (rows) {
                    window.chart_rows = rows;
                    chartCallback(rows);
                }
            });
        } else {
            chartCallback(window.chart_rows);
        }


        function chartCallback(rows) {
            var data = new google.visualization.DataTable();
            data.addColumn('string', 'Procedure');
            data.addColumn('number', 'Slices');

            var data_rows = [];
            var index = 0;
            for (key in rows.procedure_breakdown) {
                data_rows[index] = [key, Math.abs(rows.procedure_breakdown[key])];
                index++;
            }

            if (!data_rows.length) {
                $('#proceduresBreakdownID').hide();
            } else {
                $('#proceduresBreakdownID').show();
            }
            data_rows.sort(function (a, b) {
                return b[1] > a[1] ? 1 : -1;
            });
            data.addRows(data_rows);

            colour_list = [
                '#3366CC',
                '#f8f000',
                '#FF9900',
                '#109618',
                '#990099',
                '#3B3EAC',
                '#0099C6',
                '#DD4477',
                '#66AA00',
                '#B82E2E',
                '#316395',
                '#994499',
                '#22AA99',
                '#AAAA11',
                '#6633CC',
                '#E67300',
                '#8B0707',
                '#329262',
                '#5574A6',
                '#3B3EAC]'
            ];

            var options = {
                legend: 'none',
                width: '100%',
                height: '100%',
                colors: colour_list,
                tooltip: {
                    ignoreBounds: true,
                    trigger: 'selection'
                },
                sliceVisibilityThreshold: 0
            };

            chart = new google.visualization.PieChart(document.getElementById('chart_div'));
            createLegendNodes(data_rows, colour_list);
            item = {
                item_id: null,
                is_already_selected: function (item_id) {
                    return this.item_id == item_id
                }
            }
            chart.draw(data, options);
            if (window._chartCounter == 1) {
                $(window).on("throttledresize", drawChart);
            }
        }
        
    }

    window._chartCounter = 0;
}

