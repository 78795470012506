function generateJson() {
    var allRatings = $('.allCompareRatings');
    var blocks = $('#mobileRatingBlock').is(':hidden') ? allRatings.find('.smile-rating-module.jsDesktop') : allRatings.find('.smile-rating-module.jsMobile');
    var data = {};

    $.each(blocks, function (index, block) {
        var dentist_criteria = $(block).find('.dentist-criteria');
        $.each(dentist_criteria, function (index, el) {
            var smileIndex = $(el).find('.relative .on').last().attr('data-index');
            data[$(el).find('.compare-rating-key').val()] = smileIndex;
        })
    });
    return data
}

function createComparisonLike() {
    var btn = $('.jsContrlBtns');
    var smiles = generateJson();
    if (!smiles) return;
    var data = {
        'csrfmiddlewaretoken': window.csrf_token,
        'rating': JSON.stringify(smiles)
    };


    $.ajax({
        method: "POST",
        url: location.href,
        dataType: "json",
        data: data,
        beforeSend: function () {
            btn.prop('disabled', true);
            loader.show();
        },
        success: function (response) {
            if (response.success) {
                if (response.posted_twice_before && response.review_id) {
                    $('#comparisonId').val(response.review_id);
                    loader.hide();
                    localStorage.setItem('post-compare', response.review_id);
                    $('#postLikeModal').modal('show');
                } else if (!response.auth_user) {
                    loader.hide();
                    btn.prop('disabled', false);
                    $('#postAnonymusModal').modal('show');
                    $('#reviewId').val(response.review_id);
                } else {
                    loader.hide();
                    toastr.success('Your rating was successfully created!');
                    setTimeout(function () {
                        location.href = location.origin + '/' + response.slug_second + '#read_review';
                    }, reloadTimeOut);
                }
            } else {
                toastr.error('Something went wrong!')
            }
        }
    });
}

var REQUIRED_CRITERIA_NUM = 9;

$(document).ready(function() {

    $('#comparisonDentistsList' ).val('');
    $('#comparisonDentistsList').change(function(){
        var value = $(this).val();
        if (value) {
            location.href = value;
        }
    });

    $('#comparisonDentistsListMobile').val('');
    $('#comparisonDentistsListMobile').change(function(){
        var value = $(this).val();
        if (value) {
            location.href = value;
        }
    });



    $('#compareReviewBtnSubmit').click(createComparisonLike);

    var addReviewRatingScore1 = {
        'firstDentist': ['','','','','','','','',''],
        'secondDentist': ['','','','','','','','','']
    };

    var additionalRatingScore = {
        'firstDentist': ['','',''],
        'secondDentist': ['','','']
    };

    function initComparisonReview() {
        /* VISUAL PART FOR HOVER AND CLICKS ON SMILEYS */

        $('.jsComparisonReviewSmiles .smile').on('click', function(e) {
            var thisDentistCounter;
            var thisGroupIndex = $(this).attr('data-index');
            var otherDentistCounter;
            var otherGroupIndex;
            var mainIndex = $(this).parent().attr('data-groupindex');
            var $this = $(this);
            var $this_wrap = $(this).parent();
            var $other_wrap = $(this).parents('.dentist-criteria').siblings().find('.smileys-wrap');

            if ( $this_wrap.hasClass('jsFirstDentist') ) {
                thisDentistCounter = 'firstDentist';
                otherDentistCounter = 'secondDentist';
            } else {
                thisDentistCounter = 'secondDentist';
                otherDentistCounter = 'firstDentist';
            }

            /* SETUP CURRENT ICON */
            $this.addClass('on');
            $this.siblings().removeClass('on');


            /* SETUP CURRENT TEXT AND SIBLINGS TEXT */
            if ( $this.hasClass('icon-thumbs-up') ) {
                $this_wrap.removeClass('negative negative2');

                if ( $other_wrap.hasClass('positive') || $other_wrap.hasClass('positive2')  ) {
                    $this_wrap.removeClass('positive').addClass('positive2');
                    $other_wrap.removeClass('positive').addClass('positive2');
                } else {
                    $this_wrap.removeClass('positive2').addClass('positive');
                    $other_wrap.removeClass('negative2').addClass('negative');
                }

            } else {
                $this_wrap.removeClass('positive positive2');

                if ( $other_wrap.hasClass('negative') || $other_wrap.hasClass('negative2')  ) {
                    $this_wrap.removeClass('negative').addClass('negative2');
                    $other_wrap.removeClass('negative').addClass('negative2');
                } else {
                    $this_wrap.removeClass('negative2').addClass('negative');
                    $other_wrap.removeClass('positive2').addClass('positive');
                }
            }

            /* SETUP SIBLING ICON IF FIRST TIME EMPTY - SETUP OPOSITE */
            if ($this.parents('.dentist-criteria').siblings().find('.smile.on').length == 0 ) {
                $this.parents('.dentist-criteria').siblings().find('.smile'+'[data-index="'+thisGroupIndex+'"]').siblings().addClass('on');
            }

            otherGroupIndex = $this.parents('.dentist-criteria').siblings().find('.smile.on').attr('data-index');
            reviewRatingSmileClickHandled(thisDentistCounter,thisGroupIndex,otherDentistCounter,otherGroupIndex,mainIndex);


            /* CHECK IF RATING IS NOT EMPTY FOR SOME GROUP */
            var active = true;

            for (var key in addReviewRatingScore1) {
                addReviewRatingScore1[key].forEach(function(el,index) {
                    if (el == '') active = false;
                    return false;
                })
            }
            if (active) {
                $('.jsContrlBtns').prop('disabled', false);
            } else {
                $('.jsContrlBtns').prop('disabled', true);
            }
        });

        function reviewRatingSmileClickHandled(thisDentistCounter,thisGroupIndex,otherDentistCounter,otherGroupIndex,mainIndex) {
            if (mainIndex < REQUIRED_CRITERIA_NUM) {
                addReviewRatingScore1[thisDentistCounter][mainIndex] = thisGroupIndex;
                addReviewRatingScore1[otherDentistCounter][mainIndex] = otherGroupIndex;
            } else {
                additionalRatingScore[thisDentistCounter][mainIndex - REQUIRED_CRITERIA_NUM] = thisGroupIndex;
                additionalRatingScore[otherDentistCounter][mainIndex - REQUIRED_CRITERIA_NUM] = otherGroupIndex;
            }
        }
    }

    function showErrorOneDentist() {
        $('.jsComparisonReviewSmiles .smile').on('click', function(e) {
            $('.jsErrorOneDentist').show();
        });
    }

    if ( $('.jsSecondDentistAvailable').length ) {
        initComparisonReview();
    } else {
        showErrorOneDentist();
    }
});



