
function postFaceGalleryPhoto(reloadPage) {
    localStorage.removeItem('post-face');
    var formData = new FormData();
    formData.append('file', $('#jsDentalPhotoInput')[0].files[0]);
    formData.append('rating', $('#jsDentalPhotoSmileys').find('.on').length);
    $.ajax({
        type: "POST",
        url: "/face-gallery/add-patient-face/"+ $('#dentist-slug').val(),
        dataType : "json",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function() {
            loader.show();
            $('.jsPostGalBtn').attr('disabled','true');
        },
        success: function (response) {
            loader.hide();
            if (response.is_posted) toastr.success("Your photo was successfully posted");
            if (reloadPage) {
                location.reload();
            } else {
                var face_id = response.face_id;
                $('.faces-gallery-block input').val('');
                $('.jsDentalPhotoWrap').addClass('hidden');
                $('#jsDentalPhotoSmileys .smile').removeClass('on');
                $('.jsPostGalBtn').removeAttr('disabled');
                $('.faces-gallery').prepend(
                '<div class="faces-gallery-wrap relative mb-1">' +
                    '<img border="0" src="'+response.photo_url+'" class="rounded faces-gallery-image" alt="">' +
                    '<button type="button" class="close" aria-label="Delete" onclick="deleteFaceGallery(this, '+face_id+')">' +
                        '<span aria-hidden="true">×</span>' +
                    '</button>' +
                    '<div class="photo-caption"><em>'+response.user_name+'</em></div>' +
                '</div>'
                );
            }
            updateReviewPaginator();
        }
    });
}

function updateReviewPaginator() {
    $.ajax({
        method: "GET",
        url: location.origin + '/api' + location.pathname,
        success: function (data) {
            $('.jsReviewWrapper').replaceWith(data);
        }
    });
}

function deleteFaceGallery(button, face_id) {
    var formData = new FormData();
    formData.append('face_id', face_id);
    $.ajax({
        type: "POST",
        url: "/face-gallery/delete",
        dataType : "json",
        data: formData,
        processData: false,
        contentType: false,
        success: function (response) {
            if (response.deleted) {
                toastr.success("Your photo was successfully removed");
                $(button).closest('.faces-gallery-wrap').remove();
            }
        }
    });
}


function postFaceGalleryPhotoAfterLogin() {
    localStorage.setItem('post-face', true);
}
