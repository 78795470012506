$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-review-page') ) {
        var apiUrl = '/api/photos';
        var pattern = /photo/;
        var key = 'photo';
        var wrapperClass = 'jsPhotoWrapper';
        var lastIndexClass = 'jsLastPhotoIndex';
        var buttonClass = 'jsShowMoreLessPhotoBtn';

        function jsPhotoPagination() {
            var btn = $(this);
            jsInfoPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }
        hideShowButtons(pattern, buttonClass, $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsPhotoPagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-review-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetPhotoPagination() {
    resetPagination(/photo/, '/api/photos', 'photo', 'jsLastPhotoIndex', 'jsPhotoWrapper', 'jsShowMoreLessPhotoBtn');
}

