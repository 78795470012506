/* CHECK USERS POSITION BY COORDS INPUT */
var googleKeyAPI = 'AIzaSyCwy2j_1_XMuwP0N29MpcqgImSNoeJnEsg'

function showPosition(position) {
    var coords = position.coords.latitude + ',' + position.coords.longitude;
    //var coords = '36.731527,-119.757692' // Test coords
    var data = {
        'latlng': coords,
        'key': googleKeyAPI,
        'language': 'en'
        };

    $.get('https://maps.googleapis.com/maps/api/geocode/json', data, function(response) {
        if (response.status == "OK") {
            var zipcode = 0;
            var city = '';
            var state = '';
            var address = response.results[0];
            for (var index = 0; index < address.address_components.length; index++) {
                if (address.address_components[index].types[0] == "political") {
                    city = address.address_components[index].short_name;
                }
                if (address.address_components[index].types[0] == "administrative_area_level_1") {
                    state = address.address_components[index].short_name;
                }
                if (address.address_components[index].types[0] == "postal_code") {
                    zipcode = address.address_components[index].short_name;
                }
            }
            if (zipcode || city) {
                if (zipcode == 0) {zipcode = null};
                var location = {
                    "zipcode": zipcode,
                    'city': city,
                    'state': state,
                }
                $.get('/search-location/', location, function(response) {
                    window.sessionStorage.setItem('not_first_login', true);
                });
            }
            if (zipcode) {
                if (zipcode[0] == '0') {
                    zipcode = zipcode.split(/0(.+)/)[1]
                }
                window.location = "/?zip-code=" + zipcode;
            } else if (city && state) {
                window.location = "/?city=" + city.replace(' ', '-').toLowerCase() + '&state=' + state.toLowerCase();
            } else {
                $('#locationNotFoundModal').modal('show');
                setTimeout(function() {
                    $('#locationNotFoundModal').modal('hide');
                }, 1500);
            }

        } else {
            console.log("errors", response)
        }
        $('#getLocationButtonID').removeAttr('disabled');

    });
}

function saveCurrentLocation() {
    $.get('/search-location/', {'saved_location': location.search}, function(response) {
        if (response.status == 200) {
            $('#saveCurrentLocationButtonID span').html('<i class="icon-location"></i> Saved ' + response.saved_name);
            $("#getLocationButtonID").hide();
            $("#forgetCurrentLocationButtonID").parent().removeClass('hidden');
            $("#savedLocationHeaderID").attr("href", response.saved_slug)
            $("#savedLocationHeaderID").text(response.saved_name + " (Saved)")
            window.sessionStorage.setItem('not_first_login', true)
        }
    });
}

function forgetCurrentLocation() {
    $.get('/search-location/', {'forget': 'true'}, function(response) {
        if (response.status == 200) {
            $('#saveCurrentLocationButtonID span').html('<i class="icon-floppy"></i>  Save this location');
            $("#forgetCurrentLocationButtonID").parent().addClass('hidden');
            window.sessionStorage.setItem('not_first_login', true)
        }
    });
}

function getLocation(el) {
    $(el).attr('disabled','disabled');
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
        toastr.error('Geolocation is not supported by this browser.');
    }
}

function showError(error) {
    switch(error.code) {
        case error.PERMISSION_DENIED:
            toastr.error("User denied the request for Geolocation.");
            break;
        case error.POSITION_UNAVAILABLE:
            toastr.error("Location information is unavailable.");
            break;
        case error.TIMEOUT:
            toastr.error("The request to get user location timed out.");
            break;
        case error.UNKNOWN_ERROR:
            toastr.error("An unknown error occurred.");
            break;
    }
    $('#getLocationButtonID').removeAttr('disabled');
}