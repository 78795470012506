$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/videos';
        var pattern = /video/;
        var key = 'video';
        var wrapperClass = 'jsManageVideoWrapper';
        var lastIndexClass = 'jsLastManageVideoIndex';
        var buttonClass = 'jsShowMoreLessManageVideoBtn';

        function jsManageVideoPagination() {
            var btn = $(this);
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManageVideoPagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-management-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetManageVideoPagination() {
    resetManagePagination(/video/, 'api/videos', 'video', 'jsLastManageVideoIndex', 'jsManageVideoWrapper', 'jsShowMoreLessManageVideoBtn');
}
