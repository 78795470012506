var treatment_url = location.origin + '/prices/api/treatments';
var procedure_url = location.origin + "/prices/api/procedures/";
var type_url = location.origin + "/prices/api/types/";
var loader = $(".throbber-wrapper");
var files = [];

var dialogCloseTime = 100;
var dialogOpenTime = 500;
var reloadTimeOut = 1000;


function updateNameAddress(e) {
    if (!$('#address-second').is(":checked") ) {
        var phone_second = $('#phone_second');
        phone_second.removeClass('bfh-phone');
        phone_second.removeAttr('required');
        phone_second.val('');
        phone_second.attr('data-parsley-required', 'false');
        // phone_second.removeAttr('data-format');
        $('#address_second').parsley({required:false});
        $('#state_second').parsley({required:false});
        $('#zipcode_zip_second').parsley({required:false});
        $('#city_second').parsley({required:false});
        phone_second.parsley({required:false});
    } else if ($('#address-second').is(":checked") && $('#address_second').val() === '' || $('#zipcode_zip_second').val() === '' || $('#city_second').val() === '' || $('#phone_second').val() === ' ' || $('#state_second').val() === '') {
        toastr.error('Please fill or remove all fields related to second addresss');
        $('#address_second').parsley({required:true});
        $('#zipcode_zip_second').parsley({required:true});
        $('#city_second').parsley({required:true});
        $('#state_second').parsley({required:true});
        $('#phone_second').parsley({required:true});
    }

    if (!$('#address-third').is(":checked") ) {
        var phone_third = $('#phone_third');
        phone_third.removeClass('bfh-phone');
        phone_third.removeAttr('required');
        phone_third.val('');
        phone_third.attr('data-parsley-required', 'false');
        $('#address_third').parsley({required:false});
        $('#state_third').parsley({required:false});
        $('#zipcode_zip_third').parsley({required:false});
        $('#city_third').parsley({required:false});
        phone_third.parsley({required:false});
    } else if ($('#address-third').is(":checked") && $('#address_third').val()==='' || $('#zipcode_zip_third').val()==='' || $('#city_third').val() === '' || $('#phone_third').val() === ' ' || $('#state_third').val() === '') {
        toastr.error('Please fill or remove all fields related to third addresss');
        $('#address_third').parsley({required:true});
        $('#state_third').parsley({required:true});
        $('#zipcode_zip_third').parsley({required:true});
        $('#city_third').parsley({required:true});
        $('#phone_third').parsley({required:true});
    }
    var form = $('#updateNamePasswordForm');
    $(".text-danger").hide();
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        e.preventDefault();
        $("#updateNameAddress").addClass('disabled');
        var formData = new FormData(form[0]);
        $.ajax({
            type: "POST",
            url: "update-name-address/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $("#updateNameAddress").removeClass('disabled');
                if (result.success) {
                   toastr.success('Information successfully updated!');
                   setTimeout(function(){ location.reload() }, reloadTimeOut);
                } else {
                    var errors = JSON.parse(result.errors);
                    $("#updateNameAddress").removeClass('disabled');
                    for (var index in errors) {
                        $("#error_"+index).text(errors[index][0].message).show();
                    }
                }
            }
        });
    }
}

function disableFields(id, class_name) {
    if (!$('#address-second').is(":checked") && $('#address_second').val() !== '') {
        $('#address-second-error').css('display', 'block');
    } else if (($('#address-second').is(":checked") && $('#address_second').val() !== '')) {
         $('#address-second-error').css('display', 'none');
    }

    if (!$('#address-third').is(":checked") && $('#address_third').val() !== '') {
        $('#address-third-error').css('display', 'block');
    } else if (($('#address-third').is(":checked") && $('#address_third').val() !== '')) {
         $('#address-third-error').css('display', 'none');
    }

    if ($(id).is(":checked")) {
        $(class_name).each(function (index, elem) {
            $(elem).prop("disabled", false);
        });
    } else {
        $(class_name ).each(function (index, elem) {
            $(elem).prop("disabled", true);
        });
    }
}

function deleteAddress(address_key) {
    console.log(address_key);

    var formData = new FormData();
    formData.append('address-key', address_key);

    loader.show();
    $.ajax({
        type: "POST",
        url: "delete-address/",
        dataType: 'json',
        data: formData,
        processData: false,
        contentType: false,
        success: function(result) {
            loader.hide();
            if (result.success){
                toastr.success('Address successfully removed!');
                setTimeout(function(){ location.reload() }, reloadTimeOut);
            }
            else {
                toastr.error('Unknown error!');
                location.reload();
            }
        }
    });

}

function compareTimes(from,to) {
    var fromVal = from.val();
    var toVal = to.val();
    var str = '<span class="schedule_error">This field cannot be empty</span>';
    var err = '<span class="schedule_error">Opening hours cannot be after closing hours</span>';
    if (fromVal != '' && toVal != '') {
        if ( Date.parse('01/01/2011 '+fromVal) >= Date.parse('01/01/2011 '+toVal)) {
            $('.jsTagDanger').css('display','block');
            from.parents('.form-group').addClass('compare-error');
        } else {
            from.parents('.form-group').removeClass('compare-error');
        }
    } else if (fromVal == '' && toVal != '') {
        from.addClass('parsley-error');
        from.parent().parent().append(str);
    } else if (fromVal != '' && toVal == '') {
        to.addClass('parsley-error');
        to.parent().parent().append(str);
    }
}

function updateOfficeHours(e) {
    $(".text-danger").hide()
    var timepickers = $(".timeClock")
    var validTime = true;
    for (var index=0; index < timepickers.length; index++) {
        var start = $(timepickers[index]).find('.start');
        var end = $(timepickers[index]).find('.end');
        var errors = $(timepickers[index]).find('.text-danger');
        if (!start.val() && end.val()) {
            $(errors[0]).show();
            validTime = false;
            return false;
        } else if (start.val() && !end.val()) {
            $(errors[1]).show();
            validTime = false;
            return false;
        }
        var date1 = Date.parse('01/01/2001 ' + start.val());
        var date2 = Date.parse('01/01/2001 ' + end.val());
        if (start.val() && !date1) {
            $(errors[0]).show();
            validTime = false;
            return false;
        }
        if (end.val() && !date2) {
            $(errors[1]).show();
            validTime = false;
            return false;
        }
        if (date1 > date2) {
            $(errors[2]).show();
            validTime = false;
            return false;
        }
    }
    var form = $('#updateOfficeHoursForm');
    var form_parsley = form.parsley();
    $("#updateOfficeHours").addClass('disabled');
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "update-office-hours/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $("#updateOfficeHours").removeClass('disabled');
                if (result.success) {
                    toastr.success('Office hours successfully updated!');
                }
                else {
                    var errors = JSON.parse(result.errors);
                    for (var index in errors) {
                        $("#error_"+index).text(errors[index][0].message).show();
                    }
                }
            }
        });
    }
}

function uploadDentistForm() {
    var tab = $('.jsMainNavItem.active');
    if (tab) {
        tab = tab[0].id;
    }
}

function updatePhoto(photo_id) {
    $("#update-" + photo_id).addClass('disabled');
    var form = $('#form-' + photo_id);
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $.ajax({
            type: "POST",
            url: "update-office-photo/" + photo_id + "/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $("#update-" + photo_id).removeClass('disabled').parent().hide();
                if (result.success) {
                    $('#description-' + photo_id).text(result.description);
                    $('#description-' + photo_id).attr('readonly','true');
                    toastr.success('Description successfully updated!')
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function editPhoto(e,photo_id) {
    $(e.target).parents('.jsExistPhotoBlock').find('.update-controls').show();
    $('#description-' + photo_id).removeAttr('readonly').trigger('focus')
}

function deletePhoto(photo_id) {
    var form = $('#form-' + photo_id);
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteOfficePhotoDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteOfficePhotoDialogNo'>No</button></div>", 'Are you sure you want to delete this office photo?',
        {
            showDuration: dialogCloseTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                loader.show();
                $('#deleteOfficePhotoDialogYes').click(function(){
                    $.ajax({
                        type: "POST",
                        url: "delete-office-photo/" + photo_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('Photo successfully deleted!');
                                setTimeout(function(){
                                    location.href = location.origin + location.pathname + '#addPhotoForm';
                                }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }

                        }
                    });
                });
                $('#deleteOfficePhotoDialogNo').click(function(){
                    toastr.clear();
                    loader.hide();
                });
            }
        });
    }
}

function addPhoto(e) {
    $('#addOfficePhoto').attr('disabled', true);
    var form = $('#addPhotoForm');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        e.preventDefault();
        loader.show();
        $.ajax({
            type: "POST",
            url: "add-office-photo/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                if (result.success){
                    toastr.success('Photo successfully uploaded!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function addVideo(e) {
    $("#video-error-video").hide();
    $("#video-error-title").hide();
    var form = $('#addVideoForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#addOfficeVideo').attr('disabled', true);
        var formData = new FormData(form[0]);
        e.preventDefault();
        loader.show();
        $.ajax({
            type: "POST",
            url: "add-office-video/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                $('#addOfficeVideo').attr('disabled', false);
                if (result.success) {
                    toastr.success('Video successfully uploaded!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    var errors = JSON.parse(result.errors);
                    for (var index in errors) {
                        $("#video-error-" + index).text(errors[index][0].message).show();
                    }
                }
            }
        });
    }
}

function updateVideo(video_id) {
    $('#video-error-title-' + video_id).hide();
    var form = $('#form-video-' + video_id);
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $.ajax({
            type: "POST",
            url: "update-office-video/" + video_id + "/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                if (result.success) {
                    $("#title-header-" + video_id).text(result.title);
                    $('#title-' + video_id).text(result.description);
                    $('#title-' + video_id).attr('readonly','true');
                    toastr.success('Video title successfully updated!')
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function editVideo(e,video_id) {
    $(e.target).parents('.jsExistVideoBlock').find('.update-controls').show();
    $('#title-' + video_id).removeAttr('readonly').trigger('focus');
}

function deleteVideo(video_id) {
    var form = $('#form-video-' + video_id);
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteOfficeVideoDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteOfficeVideoDialogNo'>No</button></div>", 'Are you sure you want to delete this office video?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteOfficeVideoDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-office-video/" + video_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('Video successfully deleted!');
                                setTimeout(function(){
                                    location.href = location.origin + location.pathname + '#jsMainNavTab14';
                                }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                 $('#deleteOfficeVideoDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function updatePracticeDescription(e) {
    $('#practiceUpdateSubmit').prop('disabled', true);
    var form = $('#practice-update-form');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "update-practice-description/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#practiceUpdateSubmit').prop('disabled', false);
                if (result.success) {
                    $('#practiceUpdateSubmit').parents('.jsExistDescription').find('.materialize-textarea').attr('readonly','true');
                    toastr.success('Practice description successfully updated!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function editPracticeDescription(e) {
    e.preventDefault();
    $(this).parent().hide();
    var el = $(this).parents('.jsExistDescription');
    el.find('.jsUpdateControl').show();
    el.find('.materialize-textarea').removeAttr('readonly');
}

function updateOfficeEquipment(e) {
    $('#equipmentUpdateSubmit').prop('disabled', true);
    var form = $('#equipmentUpdateForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        loader.show();
        var formData = new FormData(form[0]);
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "update-office-equipment/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#equipmentUpdateSubmit').prop('disabled', false);
                if (result.success) {
                    toastr.success('Office equipment successfully updated!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function changeMainPhotoPhotos(e) {
    var form = $('#changeMainPhotoForm2');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        e.preventDefault();
        loader.show();
        $.ajax({
            type: "POST",
            url: "change-main-photo/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                if (result.success) {
                    toastr.success("Main photo successfully added!");
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function deleteMainPhoto(e) {
    var form = $('#deleteMainPhotoForm');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        e.preventDefault();
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteMainPhotoDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteMainPhotoDialogNo'>No</button></div>", 'Are you sure you want to delete your main photo?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteMainPhotoDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-main-photo/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success("Main photo successfully deleted!");
                                setTimeout(function(){ location.reload() }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                $('#deleteMainPhotoDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function changeMainPhoto() {
    $('#changeMainPhotoWrapper').toggle('hidden');
    $('#changeMainOfficePhoto').text('Change Image');
}

function createDentistProcedure(e) {
    var form = $('#addProceduresForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#addProcedure').attr('disabled', true);
        var formData = new FormData(form[0]);
        e.preventDefault();
        loader.show();
        $.ajax({
            type: "POST",
            url: "add-dentist-procedure/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                if (result.success){
                    resetManageArticlePagination();
                    toastr.success('Successfully added!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function changeDentistProcedure(procedure_id) {
    if ($('#form-procedure-' + procedure_id + '-update').is(':visible')) {
        $('#form-procedure-' + procedure_id + '-update').hide();
    } else {
        $('#form-procedure-' + procedure_id + '-update').show();
    }
}

function deleteDentistProcedure(procedure_id) {
    var form = $('#form-procedure-' + procedure_id + '-delete');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteDentistProcedureDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteDentistProcedureDialogNo'>No</button></div>", 'Are you sure you want to delete this procedure?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteDentistProcedureDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-procedure/" + procedure_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('Procedure successfully deleted!');
                                setTimeout(function(){ location.reload(); }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                 $('#deleteDentistProcedureDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function updateProcedure(procedure_id) {
    var form = $('#form-procedure-' + procedure_id + '-update');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $.ajax({
            type: "POST",
            url: "update-procedure/" + procedure_id + "/",
            dataType: 'json',
            data: formData,
            beforeSend: function () {
              loader.show();
            },
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                if (result.success) {
                    toastr.success('Successfully updated!');
                    $('#form-procedure-' + procedure_id + '-update').toggle('hidden');
                    $("#title-procedure-" + procedure_id).text(result.title);
                    $("#description-procedure-" + procedure_id).text(result.description);
                    if (result.photo) {
                        $("#photo-procedure-" + procedure_id).attr('src', result.photo);
                    } else {
                        $("#photo-procedure-" + procedure_id).hide();
                    }
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function addBeforeAfterCase(e) {
    var form = $('#addBeforeAfterCaseForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        loader.show();
        $('#addBeforeAfterCaseSubmit').prop('disabled', true);
        var formData = new FormData(form[0]);
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "add-before-after-case/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                $('#addBeforeAfterCaseSubmit').prop('disabled', false);
                if (result.success) {
                    toastr.success('Before & after case successfully added!');
                    setTimeout(function(){ location.reload(); }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function deleteBeforeAfterCase(case_id) {
    var form = $('#delete-bef-aft-case-' + case_id + '-form');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteBefAftCaseDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteBefAftCaseDialogNo'>No</button></div>", 'Are you sure you want to delete this before & after case?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteBefAftCaseDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-before-after-case/" + case_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('Before & after case successfully deleted!');
                                setTimeout(function(){ location.reload() }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                 $('#deleteBefAftCaseDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function updateBeforeAfterCase(case_id) {
    var form = $('#case-update-' + case_id);
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $('#update-case-button-' + case_id).prop('disabled', true);
        loader.show();
        $.ajax({
            type: "POST",
            url: "update-before-after-case/" + case_id + "/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                $('#update-case-button-' + case_id).prop('disabled', false);
                if (result.success) {
                    $("#header-case-title-" + case_id).text(result.before_after_case.title);
                    $("#case-title-" + case_id).text(result.before_after_case.title);
                    $("#case-before-desc-" + case_id).val(result.before_after_case.description);
                    $("#case-after-desc-" + case_id).val(result.before_after_case.after_description);
                    $("#case-before-photo-" + case_id).attr('src', result.before_after_case.photo);
                    $("#case-after-photo-" + case_id).attr('src', result.before_after_case.after_photo);
                    el = $('#before-after-case-' + case_id);
                    el.find('.description-controls .material-input').attr('readonly','true');
                    el.find('.file-field').hide();
                    el.find('.update-controls').hide();
                    toastr.success('Before & after case successfully updated!');
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function editBeforeAfterCase(case_id) {
    el = $('#before-after-case-' + case_id);
    el.find('.description-controls .material-input').removeAttr('readonly');
    el.find('.file-field').show();
    el.find('.update-controls').show();
}

function addArticle(e) {
    var form = $('#createArticleForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#createArticleSubmit').prop('disabled', true);
        var formData = new FormData(form[0]);
        e.preventDefault();
        loader.show();
        $.ajax({
            type: "POST",
            url: "create-article/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#createArticleSubmit').prop('disabled', false);
                loader.hide();
                if (result.success) {
                    toastr.success('Article successfully created!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function deleteArticle(article_id) {
    var form = $('#delete-article-' + article_id + '-form');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteArticleDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteArticleDialogNo'>No</button></div>", 'Are you sure you want to delete this article?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteArticleDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-article/" + article_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('Article successfully deleted!');
                                setTimeout(function(){ location.reload() }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                 $('#deleteArticleDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function updateArticle(e,article_id) {
    var form = $('#update-article-form-' + article_id);
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $('#update-article-button-' + article_id).prop('disabled', true);

        $.ajax({
            type: "POST",
            url: "update-article/" + article_id + "/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#update-article-button-' + article_id).prop('disabled', false);
                if (result.success) {
                    $("#header-article-title-" + article_id).text(result.article.title);
                    $("#article-title-" + article_id).text(result.article.title);
                    $("#article-content-" + article_id).val(result.article.text);
                    $("#article-title-" + article_id).attr('readonly','true');
                    var el = $(e.target).parents('.jsExistArticle');
                    el.find('.jsHoverToggle').addClass('addImageBlock');
                    el.find('.updateControl').hide();
                    toastr.success('Article successfully updated!');
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function editArticle(e,article_id) {
    $("#article-title-" + article_id).removeAttr('readonly');
    var el = $(e.target).parents('.jsExistArticle');
    el.find('.jsHoverToggle').removeClass('addImageBlock');
    el.find('.updateControl').show();
}

function prepareUpload(event) {
  files = event.target.files;
}

function addQuestionAnswer(e) {
    var form = $('#create-QaA-form');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#create-QaA-submit').prop('disabled', true);
        var formData = new FormData(form[0]);
        e.preventDefault();
        loader.show();
        $.ajax({
            type: "POST",
            url: "create-dentist-question/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#create-QaA-submit').prop('disabled', false);
                loader.hide();
                if (result.success) {
                    toastr.success('FAQ successfully created!');
                    setTimeout(function(){ location.reload() }, 800);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function updateQaA(e, faq_id ){
    var form = $('#update-QaA-form-' + faq_id);
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        loader.show();
        var formData = new FormData(form[0]);
        $('update-QaA-button-' + faq_id).prop('disabled', true);
        $.ajax({
            type: "POST",
            url: "update-dentist-question/" + faq_id ,
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('update-QaA-button-' + faq_id).prop('disabled', false);
                if (result.success) {
                    toastr.success('FAQ successfully updated!');
                    setTimeout(function(){ location.reload() }, 800);
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
                loader.hide();
            },
            error: function () {
                toastr.error('Unknown error!');
                location.reload();
            }
        });
    }
}

function deleteQaA(faq_id){
    var form = $('#delete-faq-' + faq_id + '-form');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteFaqDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteFaqDialogNo'>No</button></div>", 'Are you sure you want to delete this FAQ?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteFaqDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-dentist-question/" + faq_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('FAQ successfully deleted!');
                                setTimeout(function(){ location.reload() }, 800);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                 $('#deleteFaqDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}


function editNews(e, news_id) {
    $("#news-title-" + news_id).removeAttr('readonly');
    var el = $(e.target).parents('.jsExistNews');
    el.find('.jsHoverToggle').removeClass('addImageBlock');
    el.find('.updateControl').show();
    el.find('.file-field').show();
    el.find('.update-controls').show();
}

function editQaA(e, faq_id) {
    $("#question-" + faq_id).removeAttr('readonly');
    var el = $(e.target).parents('.jsExistFaqs');
    el.find('.jsHoverToggle').removeClass('addImageBlock');
    el.find('.updateControl').show();
    el.find('.file-field').show();
    el.find('.update-controls').show();
}

function updateNews(e,news_id) {
    var form = $('#update-news-form-' + news_id);
    var form_parsley = form.parsley();
    form_parsley.validate();

    if (form_parsley.isValid()) {
        loader.show();

        var rawFormData = form.serializeArray();
        var formData = new FormData();

        rawFormData.forEach(function (element) {
            if (element.name !== 'photo') {
                formData.append(element.name, element.value);
            }
        });
        formData.append('photo', files[0]);

        $('#update-news-button-' + news_id).prop('disabled', true);
        $.ajax({
            type: "POST",
            url: "update-news/" + news_id + "/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $('#update-news-button-' + news_id).prop('disabled', false);
                if (result.success) {
                    $("#header-news-title-" + news_id).text(result.news.title);
                    $("#news-title-" + news_id).text(result.news.title);
                    $("#news-content-" + news_id).val(result.news.text);
                    $("#news-title-" + news_id).attr('readonly','true');
                    var el = $(e.target).parents('.jsExistNews');
                    el.find('.jsHoverToggle').addClass('addImageBlock');
                    el.find('.updateControl').hide();
                    el.find('.file-field').hide();
                    el.find('.update-controls').hide();
                    toastr.success('News item updated!');
                    loader.hide();
                    location.reload();
                }
                else {
                    toastr.error('Unknown error!');
                    location.reload();
                }
            }
        });
    }
}

function deleteNews(news_id) {
    var form = $('#delete-news-' + news_id + '-form');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteNewsDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteNewsDialogNo'>No</button></div>", 'Are you sure you want to delete this news?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteNewsDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-news/" + news_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('News item deleted!');
                                setTimeout(function(){ location.reload() }, reloadTimeOut);
                            }
                            else {
                                toastr.error('Unknown error!');
                                location.reload();
                            }
                        }
                    });
                });
                 $('#deleteNewsDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function addOfficeComputer(e) {
    var form = $('#createIpForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#createIpSubmit').prop('disabled', true);
        var formData = new FormData(form[0]);
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "create-office-ip/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                if (result.error) {
                    toastr.error(result.error);
                    $('#createIpSubmit').prop('disabled', false);
                }
                else if (result.success) {
                    toastr.success('','Office computer IP successfully added!', { timeOut:2000 });
                    $('#delete-ip-form').append('<div id="ip-container-'+result.pk+'"><div class="form-group"><h4 class="text align-left"><strong>' + result.ip + '</strong></h4></div><div class="form-group"><button type="button" class="btn btn-sm btn-danger mx-2" onclick="deleteIp(' + result.pk +')">Delete</button></div></div>');
                    $('#officePC').attr('hidden', false);

                    if (result.ip_count > 2) {
                         $('#createIpSubmit').prop('disabled', true);
                    } else {
                        $('#createIpSubmit').prop('disabled', false);
                    }
                }
                else {
                    toastr.error('', 'Unknown error!', { timeOut:2000 });
                    location.reload();
                }
            }
        });
    }
}

function deleteIp(pk) {
    var data = {
        'csrfmiddlewaretoken': window.csrf_token,
        'pk': pk
    };
    toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteIPDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteIPDialogNo'>No</button></div>", 'Are you sure you want to delete this office computer IP?',
    {
        showDuration: dialogOpenTime,
        hideDuration: dialogCloseTime,
        timeOut: 5000,
        extendedTimeOut: 5000,
        closeButton: false,
        allowHtml: true,
        onShown: function(toast) {
            $('#deleteIPDialogYes').click(function(){
                $.ajax({
                    type: "POST",
                    url: "delete-office-ip/",
                    dataType: 'json',
                    data: data,
                    success: function(result) {
                        if (result.success) {
                            $('#ip-container-' + pk).remove();
                            toastr.success('Office computer ip was successfully deleted!');
                            if (result.ip_count < 3) {
                                $('#createIpSubmit').prop('disabled', false);
                            }
                        }
                        else {
                            toastr.error('Unknown error!');
                            location.reload();
                        }
                    }
                });
            });
             $('#deleteIPDialogNo').click(function(){
                toastr.clear();
            });
        }
    });
}

function createCoupon(e) {
    var form = $('#addCoupon');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        $('#addCouponSubmit').prop('disabled', true);
        var formData = new FormData(form[0]);
        loader.show();
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "create-coupon/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                if (result.success) {
                    toastr.success('New patient coupon was successfully created!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                } else {
                    $('#addCouponSubmit').prop('disabled', false);
                    toastr.error('Wrong input data!');
                }
            },
            error: function () {
                loader.hide();
                toastr.error('Unknown error');
                location.reload();
            }
        });
    }
}

function updateCoupon(coupon_id) {
    var form = $('#changeCouponForm');
    var form_parsley = form.parsley();
    form_parsley.validate();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        $('#editCouponSubmit').prop('disabled', true);
        loader.show();
        $.ajax({
            type: "POST",
            url: "update-coupon/" + coupon_id + "/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                loader.hide();
                $('#editCouponSubmit').prop('disabled', true);
                if (result.success) {
                    toastr.success('Coupon was successfully updated!');
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                } else {
                    toastr.error('Wrong input data!');
                    location.reload();
                }
            },
            error: function () {
                loader.hide();
                toastr.error('Unknown error');
                location.reload();
            }
        });
    }
}

function deleteCoupon(coupon_id) {
    var form = $('#deleteCouponForm');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        var formData = new FormData(form[0]);
        toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteCouponDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteCouponDialogNo'>No</button></div>", 'Are you sure you want to delete this new patient special?',
        {
            showDuration: dialogOpenTime,
            hideDuration: dialogCloseTime,
            timeOut: 5000,
            extendedTimeOut: 5000,
            closeButton: false,
            allowHtml: true,
            onShown: function(toast) {
                $('#deleteCouponDialogYes').click(function(){
                    loader.show();
                    $.ajax({
                        type: "POST",
                        url: "delete-coupon/" + coupon_id + "/",
                        dataType: 'json',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(result) {
                            loader.hide();
                            if (result.success) {
                                toastr.success('Coupon was successfully deleted!');
                                setTimeout(function(){ location.reload() }, reloadTimeOut);
                            }
                        }
                    });
                });
                $('#deleteCouponDialogNo').click(function(){
                    toastr.clear();
                });
            }
        });
    }
}

function openEditCouponForm(cdt_id) {
    var edit_coupon_type = $('#edit-coupon-type');
    var edit_coupon_procedure = $('#edit-coupon-procedure');
    var edit_coupon_treatment = $('#edit-coupon-treatment');
    var edit_coupon_included =  $('#edit-coupon-included');

    if ($('.edit-coupon-block').is(':hidden')) {
        var url = location.origin + '/prices/api/cdt/' + cdt_id;
        $.ajax({
            type: "GET",
            url: url,
            dataType: 'json',
            success: function(result) {
                if (result.success) {
                    if (result.treatment) {
                        getOptions(edit_coupon_treatment, treatment_url, '', result.treatment.value);
                    }
                    if (result.type) {
                        getOptions(edit_coupon_type, type_url + result.treatment.value, '', result.type.value);
                    }
                    if (result.procedure) {
                        getOptions(edit_coupon_procedure, procedure_url + result.type.value, 'Leave Blank If Not Sure', result.procedure.value);
                    }
                    $('.edit-coupon-block').toggle('hidden');
                }
            }
        });
        TreatmentSelectorChange(edit_coupon_treatment, edit_coupon_type, edit_coupon_procedure, edit_coupon_included, type_url);
        TypeSelectorChange(edit_coupon_type, edit_coupon_procedure, edit_coupon_included, procedure_url);
        ProcedureSelectorChange(edit_coupon_procedure, edit_coupon_type, edit_coupon_included);
    } else {
        $('.edit-coupon-block').toggle('hidden');
    }

}

function changePassword(e) {
    var form = $('#changePasswordForm');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        e.preventDefault();
        $('#changePasswordButton').addClass('disabled');
        var formData = new FormData(form[0]);
        $.ajax({
            type: "POST",
            url: "update-password/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $("#changePasswordButton").removeClass('disabled');
                if (result.success) {
                   toastr.success('','Password change was successful!', {timeOut:5000}).css("width","400px");
                   resetForm(form);
                }
                else if (result.error) {
                    toastr.error('',result.error.__all__[0], {timeOut:5000}).css("width","400px");
                }
            }
        });
    }
}

function toggleAppointmentNotification(){
    var data = {
      'csrfmiddlewaretoken': window.csrf_token
    };

    var toggleButton = $('#toggleAppointmentNotification');
    var notificationStatus = $('#emailNotificationStatus');

    $.ajax({
        type: "POST",
        url: location.href,
        dataType: 'json',
        data: data,
        success: function(result) {
            if (result.success && toggleButton.attr('data-value') === 'false') {
                toggleButton.removeClass('btn-danger');
                toggleButton.find('span').text('ON');
                notificationStatus.text('OFF');
                toggleButton.attr('data-value', 'true');
                notificationStatus.addClass('text-danger').removeClass('text-success');
                $('#emailNotificationSwitches').hide();
            } else if (result.success) {
                toggleButton.addClass('btn-danger');
                toggleButton.find('span').text('OFF');
                toggleButton.attr('data-value', 'false');
                toggleButton.blur();
                notificationStatus.text('ON');
                notificationStatus.removeClass('text-danger').addClass('text-success');
            } else {
                toastr.error('','Something went wrong.Notification not updated', {timeOut:5000}).css("width","400px");
            }
        }
    });
}

function changeEmail(e) {
    var form = $('#changeEmailForm');
    var form_parsley = form.parsley();
    if (form_parsley.isValid()) {
        e.preventDefault();
        $('#changeEmailButton').addClass('disabled');
        var formData = new FormData(form[0]);
        $.ajax({
            type: "POST",
            url: "update-email/",
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                $("#changeEmailButton").removeClass('disabled');
                if (result.success) {
                   toastr.success('','Email successfully changed!', {timeOut:5000}).attr('style',"width: 400px");
                   $('#currentLogin').text('Current login email: ' + result.email);
                   resetForm(form);
                } else if (result.email_already_exist) {
                   toastr.error('','Email already exist! Please try again.', {timeOut:5000}).attr('style',"width: 400px");
                } else {
                   toastr.error('','Enter valid email.', {timeOut:5000}).attr('style',"width: 400px");
                }
            }
        });
    }
}

function updateEmailNotification() {
    var toggleButton = $('#toggleEmailNotification');
    var notificationStatus = $('#emailNotificationStatus');
    var notification = false;
    toggleButton.attr('data-value') === 'false' ? notification=false: notification=true;
    var data = {
      'csrfmiddlewaretoken': window.csrf_token,
      'all_notifications': notification
    };

    var reload = false;
    var apiUrl = location.href + "update-review-notification/";
    if (location.href.indexOf('/notifications/') !== -1) {
        apiUrl = location.href;
        reload = true;
    }

    $.ajax({
        type: "POST",
        url: apiUrl,
        dataType: 'json',
        data: data,
        success: function(result) {
           if (result.success && toggleButton.attr('data-value') === 'false') {
               toggleButton.removeClass('btn-danger');
               toggleButton.find('span').text('ON');
               notificationStatus.text('OFF');
               toggleButton.attr('data-value', 'true');
               notificationStatus.addClass('text-danger').removeClass('text-success');
               $('#emailNotificationSwitches').hide();
           } else if (result.success) {
               toggleButton.addClass('btn-danger');
               toggleButton.find('span').text('OFF');
               toggleButton.attr('data-value', 'false');
               toggleButton.blur();
               notificationStatus.text('ON');
               notificationStatus.removeClass('text-danger').addClass('text-success');
               location.reload() ? reload : $('#emailNotificationSwitches').show();
           } else {
               toastr.error('','Something went wrong.Notification not updated', {timeOut:5000}).css("width","400px");
           }
        }
    });
}

function addOtherSite(e) {
    var formButton = $(this);

    var form = $('#' + formButton.attr('data-tag') + 'Form');
    if (form.valid()) {
        e.preventDefault();
        formButton.prop('disabled', true);
        $.ajax({
            type: "POST",
            url: "add-other-site/",
            dataType: 'json',
            data: form.serialize(),
            success: function (result) {
                formButton.prop('disabled', false);
                if (result.success) {
                    toastr.success('', result.name + ' url was changed.', {timeOut: 2000});
                } else {
                    toastr.error('', result.error.__all__[0], {timeOut: 2000});
                }
            }
        });
    }
}

function resetForm(form) {
    form.parsley().reset();
    form[0].reset();
}

function toggleChangeEmailPassword() {
    var passwordForm = $('#changePasswordForm');
    var emailForm = $('#changeEmailForm');
    if($('#changePasswordRadio').prop('checked')) {
        passwordForm.removeClass('hidden');
        emailForm.addClass('hidden');
        resetForm(passwordForm);
    } else {
        passwordForm.addClass('hidden');
        emailForm.removeClass('hidden');
        resetForm(emailForm);
    }
}

// AJAX SELECT OPTIONS CREATE
function getOptions(selector, url, title, value) {
    selector.empty();
    $.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        success: function(result) {
            if (result.success) {
                selector.append($('<option></option>').attr('value', '').text(title));
                for (var i = 0; i < result.data.length; i++) {
                    var elem = result.data[i];
                    var option = $('<option></option>').attr('value', elem.value).text(elem.title)
                    if (elem.included) {
                        option.attr('data-included', elem.included);
                    }
                    selector.append(option);
                    if (value) {
                        selector.find('option[value=' + value + ']').attr('selected', 'selected');
                    }

                }
                selector.prop('disabled', false);
            }
        }
    });
}

// ===== SELECTORS CHANGE FUNCTIONS =====
function TreatmentSelectorChange(treatment_selector, type_selector, procedure_selector, included_selector, type_url) {
    treatment_selector.change(function(){
        var label = $('label[for=' + included_selector.attr('id') + ']');

        if($(this).val()) {
            getOptions(type_selector, type_url + $(this).val(), '');
        } else {
            type_selector.prop('disabled', true);
        }

        label.removeClass('active');
        included_selector.val('');
        type_selector.val('');
        procedure_selector.val('');
        procedure_selector.prop('disabled', true);
    });
}

function TypeSelectorChange(type_selector, procedure_selector, included_selector, procedure_url) {
    type_selector.change(function(){
        var label = $('label[for=' + included_selector.attr('id') + ']');
        if($(this).val()) {
            getOptions(procedure_selector, procedure_url + $(this).val(), 'Leave Blank If Not Sure');
            if(!procedure_selector.val()) {
               included_selector.val($(this).find('option:selected').text().trim());
               label.addClass('active');
            }
        } else {
            procedure_selector.prop('disabled', true);
            included_selector.val('');
            label.removeClass('active');
        }
        procedure_selector.val('');
    });
}

function ProcedureSelectorChange(procedure_selector, type_selector, included_selector) {
    procedure_selector.change(function(){
        var label = $('label[for=' + included_selector.attr('id') + ']');

        if($(this).val()) {
            included_selector.val($(this).find('option:selected').attr('data-included').toTitleCase().trim());
            label.addClass('active');
        } else {
            if (type_selector.val()) {
                included_selector.val(type_selector.find('option:selected').text().trim());
                label.addClass('active');
            } else {
                included_selector.val('');
                label.removeClass('active');
            }
        }
    });
}
// ===== END =====

function sendContactForm(e) {
    var form = $('#dentist-contact');
    $("#error_message").text('');
    $("#error_subject").text('');
    if (form.valid()) {
        $("#send-contact-form").addClass('disabled');
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "dentist-contact/",
            dataType: 'json',
            data: form.serialize(),
            success: function (result) {
                $("#send-contact-form").removeClass('disabled');
                if (result.success) {
                   toastr.success('Your message was successfully sent.');
                   form[0].reset();
                }
                else {
                    var errors = JSON.parse(result.errors);
                    $("#updateNameAddress").removeClass('disabled');
                    for (var index in errors) {
                        $("#error_"+index).text(errors[index][0].message).show();
                    }
                }
            },
            error: function (res) {
                $("#send-contact-form").removeClass('disabled');
            }
        });
    }
}

function sendDentistMarketing(e) {
    var form = $('#dentist-marketing');
    $("#send-dentist-marketing").addClass('disabled');
    $("#error_message").text('');
    $("#error_subject").text('');
    if (form.valid()) {
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: "dentist-marketing/",
            dataType: 'json',
            data: form.serialize(),
            success: function (result) {
                if (result.deleted) {
                    toastr.success('', 'Your business cards information was successfully deleted.', { timeOut: 3000 });
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                } else if (result.success) {
                    toastr.success('', 'Your business cards information was successfully updated.', { timeOut: 3000 });
                    setTimeout(function(){ location.reload() }, reloadTimeOut);
                }
                $("#send-dentist-marketing").removeClass('disabled');
            }
        });
    }
}

function showRemoveForm(review_id) {
    $('.reviewPaginatorButtons').hide();
    $('#respond-form-' + review_id).hide();
    $('#remove-form-' + review_id).show();
}

function showRespondForm(review_id) {
    $('.reviewPaginatorButtons').hide();
    $('#remove-form-' + review_id).hide();
    $('#respond-form-' + review_id).show();
}

function hideRespondForm(review_id) {
    $('.reviewPaginatorButtons').show();
    $('#respond-form-' + review_id).hide();
}

function hideRemoveForm(review_id) {
    $('.reviewPaginatorButtons').show();
    $('#remove-form-' + review_id).hide();
}

function disableEnableButton(textarea, button_id) {
    var button = $('#' + button_id);
    if ($(textarea).val().length > 1) {
        button.prop('disabled', false);
    } else {
        button.prop('disabled', true);
    }
}

function sendReviewRespondMessage(review_id){
    var postData = {
        'csrfmiddlewaretoken': window.csrf_token,
        'send_reply': true,
        'reply_text': $('.respond-textarea-' + review_id).val()
    };

    $.ajax({
        type: "POST",
        url: location.origin + '/reviews/reply/' + review_id + '/',
        dataType: 'json',
        data: postData,
        success: function (result) {
            if (result.success) {
                toastr.success('You reply was successfully sent!');
                setTimeout(function () { location.reload() }, 1000);
            }
        }
    });
}

function sendReviewRemoveRequest(review_id){
    var postData = {
        'csrfmiddlewaretoken': window.csrf_token,
        'remove_text': $('.remove-textarea-' + review_id).val()
    };
    loader.show();
    $.ajax({
        type: "POST",
        url: location.origin + '/reviews/remove/' + review_id + '/',
        dataType: 'json',
        data: postData,
        success: function (result) {
            loader.hide();
            if (result.success) {
                toastr.success('You review removal request was successfully sent!');
                Cookies.set('navTabMemory', 'jsMainNavTab30');
                Cookies.set('previousUrl', location.origin + location.pathname);
                setTimeout(function () { location.href = location.origin + location.pathname }, 1000);
            }
        },
        error: function (error) {
            console.log(error);
            setTimeout(function () { location.reload() }, 2000);
        }
    });
}

function changeNotificationSwitch(switch_name, value){
    var postData = {
        'csrfmiddlewaretoken': window.csrf_token,
        'update_field': switch_name,
        'value': value
    };
    loader.show();

    var apiUrl = location.href + 'toggle-notification/';
    if (location.href.indexOf('/notifications/') !== -1) {
        apiUrl = location.href;
    }

    $.ajax({
        type: "POST",
        url: apiUrl,
        dataType: 'json',
        data: postData,
        success: function (result) {
            loader.hide();
        },
        error: function (error) {
            loader.hide();
        }
    });
}

function showSourceCode(event,id){
    var text = $(event.target).text();
    if ( $(event.target).hasClass('jsGetLinkBtn') ) {
        $(event.target).text( text === "Get link" ? "Close" : "Get link" );
    } else {
        $(event.target).text( text === "Source code" ? "Close" : "Source code" );
    }
    
    $('#' + id).toggle('hidden');
}

function showReviewWidgetSourceCode() {
    var text = $(this).text();
    $(this).text( text === "Source code" ? "Close" : "Source code" );
    $('.reviewWidgetEmbedCode').toggle('hidden');
}

function showHiddenElement(element_class) {
    $('.' + element_class).toggle('hidden');
}

function addEquipmentPhoto(photo_file_id) {
    $('#' + photo_file_id).toggle('hidden');
}

function uncheckEquipment(checkbox, equipment_id) {
    if($(checkbox).is(":checked")) {
        if ($('#equipment-' + equipment_id + '-photo').length){
            $('#equipment-' + equipment_id + '-photo').show();
        } else {
            $('#equipment-' + equipment_id + '-add-photo-btn').show();
        }
    } else {
        $('#equipment-' + equipment_id + '-add-photo-btn').hide();
        $('#equipment-' + equipment_id + '-add-photo').hide();
        if($('#equipment-' + equipment_id + '-photo').length)
            $('#equipment-' + equipment_id + '-photo').hide();
    }
}

function removeEquipmentPhoto(equipment_id) {
    var formData = new FormData();
    formData.append('csrfmiddlewaretoken', window.csrf_token);

    toastr.warning("<br/><div class='container'><button type='button' class='btn btn-success mx-1-50' id='deleteEquipPhotoDialogYes'>Yes</button><button type='button' class='btn btn-danger mx-1-50' id='deleteEquipPhotoDialogNo'>No</button></div>", 'Are you sure you want to delete this equipment photo?',
    {
        showDuration: dialogOpenTime,
        hideDuration: dialogCloseTime,
        timeOut: 5000,
        extendedTimeOut: 5000,
        closeButton: false,
        allowHtml: true,
        onShown: function(toast) {
            $('#deleteEquipPhotoDialogYes').click(function(){
                loader.show();
                $.ajax({
                    type: "POST",
                    url: "delete-equipment-photo/" + equipment_id + "/",
                    dataType: 'json',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function(result) {
                        loader.hide();
                        if (result.success) {
                            toastr.success('Equipment photo was successfully deleted!');
                            $('#equipment-' + equipment_id + '-add-photo-btn').show();
                            $('#equipment-' + equipment_id + '-photo').hide();
                        } else {
                            toastr.error("Unknown error!");
                            location.reload();
                        }
                    }
                });
            });
            $('#deleteEquipPhotoDialogNo').click(function(){
                toastr.clear();
            });
        }
    });
}



$(document).ready(function() {
    if (!$('#address-second').is(":checked")) {
        $('.add-second').each(function (index, elem) {
             $(elem).prop("disabled", true);
        });
        $('#phone_second').removeClass('bfh-phone');
        $('#phone_second').removeAttr('required');
        $('#phone_second').attr('data-parsley-required', 'false');
    }

    if (!$('#address-third').is(":checked")) {
        $('.add-third').each(function (index, elem) {
             $(elem).prop("disabled", true);
        });
        $('#phone_third').removeClass('bfh-phone');
        $('#phone_third').removeAttr('required');
        $('#phone_third').attr('data-parsley-required', 'false');
    }


    $('#updateNameAddress').click(updateNameAddress);
    $('#updateOfficeHours').click(updateOfficeHours);
    $('#addOfficePhoto').click(addPhoto);
    $('#addOfficeVideo').click(addVideo);
    $('#practiceUpdateSubmit').click(updatePracticeDescription);
    $('#practiceEdit').click(editPracticeDescription);
    $('#equipmentUpdateSubmit').click(updateOfficeEquipment);
    $('#changeMainOfficePhoto').click(changeMainPhotoPhotos);
    $('#deleteMainPhotoSubmit').click(deleteMainPhoto);
    $('#addProcedure').click(createDentistProcedure);
    $('#update-description').trigger('autoresize');
    $('#addBeforeAfterCaseSubmit').click(addBeforeAfterCase);
    $('#createArticleSubmit').click(addArticle);
    $('#create-QaA-submit').click(addQuestionAnswer);
    $('#createIpSubmit').click(addOfficeComputer);
    $('#addCouponSubmit').click(createCoupon);
    $('.jsChangeLoginData').click(toggleChangeEmailPassword);
    $('#changePasswordButton').click(changePassword);
    $('#changeEmailButton').click(changeEmail);
    $('#toggleEmailNotification').click(updateEmailNotification);
    $('.addOtherCite').click(addOtherSite);
    $('#send-contact-form').click(sendContactForm);
    $('#send-dentist-marketing').click(sendDentistMarketing);
    $('#toggleAppointmentNotification').click(toggleAppointmentNotification);
    $('#showReviewWidgetSource').click(showReviewWidgetSourceCode);


    $('#newPhotoUploader').change(function() {
        if ($(this).val()) {
            $('#addOfficePhoto').prop('disabled', false);
        }
    });

    $('#newMainPhotoUploader').change(function() {
        if ($(this).val()) {
            $('#changeMainOfficePhoto').prop('disabled', false);
        }
    });

    $('#articleContent').change(function() {
        if ($('#articleContent').val()) {
            $('#createArticleSubmit').prop('disabled', false);
        }
    });

    $('.dentist-widget').on('click',function(){
        $(this).select();
    });

    $('#articleTitle').change(function() {
        if ($('#articleTitle').val()) {
            $('#createArticleSubmit').prop('disabled', false);
        }
    });


    $('#equipmentUpdateForm').find('input:checkbox').change(function(){
        $('#equipmentUpdateSubmit').prop('disabled', false);
    });

    $('#line-one').keyup(function(){
        $('#desc1out').text($(this).val())
    });

    $('#line-two').keyup(function(){
        $('#desc2out').text($(this).val())
    });


    var timepickers = $('.ui-timepicker-input');
    for (var index in timepickers) {
        if (timepickers[index].id) {
            $('#' + timepickers[index].id).timepicker({
                'showDuration': true,
                'timeFormat': 'g:i A',
                'disableTextInput': false,
                'noneOption': [{
                    'label': '---------------',
                    'value': '',
                }]
            });
            $('#' + timepickers[index].id).datepair();
        }
    }

    // NEW COUPON SELECTORS
    var coupon_type = $('#new-coupon-type');
    var coupon_procedure = $('#new-coupon-procedure');
    var coupon_treatment = $('#new-coupon-treatment');
    var coupon_included =  $('#coupon-included');

    getOptions(coupon_treatment, treatment_url, '');
    TreatmentSelectorChange(coupon_treatment, coupon_type, coupon_procedure, coupon_included, type_url);
    TypeSelectorChange(coupon_type, coupon_procedure, coupon_included, procedure_url);
    ProcedureSelectorChange(coupon_procedure, coupon_type, coupon_included);

    enableSummernote($('.summernote-textarea'));

    /* MADE TEXTAREA READONLY IF CLICKED OUTSIDE OF BLOCK */
    $(document).on('click',function(e) {
        if ( $(e.target).parents('.jsManageWideWrap').length != 1 ) {
            $('.jsExistPhotoBlock').find('.update-controls').hide();
            $('.jsExistPhotoBlock').find('.description-controls textarea').attr('readonly','true');

            $('.jsExistVideoBlock').find('.update-controls').hide();
            $('.jsExistVideoBlock').find('.description-controls .material-input').attr('readonly','true');

            /* CONTROL FOR BEFORE AFTER CASE */
            var el = $('.jsExistBefAftCase');
            el.find('.description-controls .material-input').attr('readonly','true');
            el.find('.file-field').hide();
            el.find('.update-controls').hide();

            /* CONTROL FOR PRACTICE DESCRIPTION */
            var el1 = $('#practiceUpdateSubmit').parents('.jsExistDescription');
            el1.find('.materialize-textarea').attr('readonly','true');
            el1.find('.jsUpdateControl').hide();
            el1.find('.jsEditControl').show();

            /* CONTROL FOR ARTICLES */
            var el2 = $('.jsExistArticle');
            el2.find('.material-input').attr('readonly','true');
            el2.find('.jsHoverToggle').addClass('addImageBlock');
            el2.find('.updateControl').hide();
        }
    });

    if ( $('.global-section').hasClass('dentist-management-page') ) {
        if ( window.location.hash == '#contact_support') {
            showMenuTab('jsMainNavTab26',$('.jsMainNavItem[data-id="jsMainNavTab26"]'));
        }
    }
});

// window.onload = function(){
//     if (Cookies.get('scrollTo')) {
//         var wrapperId = Cookies.get('scrollTo');
//         $('html,body').animate({ scrollTop: $('#'+wrapperId).offset().top - 85}, 'slow');
//         Cookies.remove('scrollTo');
//     }
// };
