$(document).ready(function () {
    // CHECK IF THIS IS DENTIST TYPE PAGE:
    if ( $('.global-section').hasClass('dentist-management-page') ) {
        var apiUrl = 'api/cases';
        var pattern = /case/;
        var key = 'case';
        var wrapperClass = 'jsManageCaseWrapper';
        var lastIndexClass = 'jsLastManageCaseIndex';
        var buttonClass = 'jsShowMoreLessManageCaseBtn';

        function jsManageCasePagination() {
            var btn = $(this);
            jsManagementPagination(pattern, apiUrl, key, lastIndexClass,wrapperClass,buttonClass, btn);
        }

        hideShowButtons(pattern, buttonClass , $('.' + lastIndexClass).text().trim().split(' ')[3]);
        $('.' + buttonClass).click(jsManageCasePagination);

        window.onpopstate = function(event) {
            if ( (event.state && event.state.counter) || $('.dentist-management-page').length ) {
                window.location.reload();
            }
        };
    }
});

function resetManageCasePagination() {
    resetManagePagination(/case/, 'api/cases', 'case', 'jsLastManageCaseIndex', 'jsManageCaseWrapper', 'jsShowMoreLessManageCaseBtn');
}
